
var CanvasKitInit = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(CanvasKitInit) {
  CanvasKitInit = CanvasKitInit || {};


null;var w;w||(w=typeof CanvasKitInit !== 'undefined' ? CanvasKitInit : {});var aa,ea;w.ready=new Promise(function(a,b){aa=a;ea=b});
(function(a){a.Rd=a.Rd||[];a.Rd.push(function(){a.MakeSWCanvasSurface=function(b){var e=b;if("CANVAS"!==e.tagName&&(e=document.getElementById(b),!e))throw"Canvas with id "+b+" was not found";if(b=a.MakeSurface(e.width,e.height))b.Nd=e;return b};a.MakeCanvasSurface||(a.MakeCanvasSurface=a.MakeSWCanvasSurface);a.MakeSurface=function(b,e){var f={width:b,height:e,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB},h=b*e*4,m=a._malloc(h);if(f=a.Surface._makeRasterDirect(f,
m,4*b))f.Nd=null,f.Ef=b,f.Af=e,f.Cf=h,f.$e=m,f.getCanvas().clear(a.TRANSPARENT);return f};a.MakeRasterDirectSurface=function(b,e,f){return a.Surface._makeRasterDirect(b,e.byteOffset,f)};a.Surface.prototype.flush=function(b){a.Od(this.Md);this._flush();if(this.Nd){var e=new Uint8ClampedArray(a.HEAPU8.buffer,this.$e,this.Cf);e=new ImageData(e,this.Ef,this.Af);b?this.Nd.getContext("2d").putImageData(e,0,0,b[0],b[1],b[2]-b[0],b[3]-b[1]):this.Nd.getContext("2d").putImageData(e,0,0)}};a.Surface.prototype.dispose=
function(){this.$e&&a._free(this.$e);this.delete()};a.Od=a.Od||function(){};a.df=a.df||function(){return null}})})(w);
(function(a){a.Rd=a.Rd||[];a.Rd.push(function(){function b(n,q,u){return n&&n.hasOwnProperty(q)?n[q]:u}function e(n){var q=fa(ja);ja[q]=n;return q}function f(n){return n.naturalHeight||n.videoHeight||n.displayHeight||n.height}function h(n){return n.naturalWidth||n.videoWidth||n.displayWidth||n.width}function m(n,q,u,D){n.bindTexture(n.TEXTURE_2D,q);D||u.alphaType!==a.AlphaType.Premul||n.pixelStorei(n.UNPACK_PREMULTIPLY_ALPHA_WEBGL,!0);return q}function v(n,q,u){u||q.alphaType!==a.AlphaType.Premul||
n.pixelStorei(n.UNPACK_PREMULTIPLY_ALPHA_WEBGL,!1);n.bindTexture(n.TEXTURE_2D,null)}a.GetWebGLContext=function(n,q){if(!n)throw"null canvas passed into makeWebGLContext";var u={alpha:b(q,"alpha",1),depth:b(q,"depth",1),stencil:b(q,"stencil",8),antialias:b(q,"antialias",0),premultipliedAlpha:b(q,"premultipliedAlpha",1),preserveDrawingBuffer:b(q,"preserveDrawingBuffer",0),preferLowPowerToHighPerformance:b(q,"preferLowPowerToHighPerformance",0),failIfMajorPerformanceCaveat:b(q,"failIfMajorPerformanceCaveat",
0),enableExtensionsByDefault:b(q,"enableExtensionsByDefault",1),explicitSwapControl:b(q,"explicitSwapControl",0),renderViaOffscreenBackBuffer:b(q,"renderViaOffscreenBackBuffer",0)};u.majorVersion=q&&q.majorVersion?q.majorVersion:"undefined"!==typeof WebGL2RenderingContext?2:1;if(u.explicitSwapControl)throw"explicitSwapControl is not supported";n=la(n,u);if(!n)return 0;ma(n);x.ge.getExtension("WEBGL_debug_renderer_info");return n};a.deleteContext=function(n){x===oa[n]&&(x=null);"object"==typeof JSEvents&&
JSEvents.ug(oa[n].ge.canvas);oa[n]&&oa[n].ge.canvas&&(oa[n].ge.canvas.yf=void 0);oa[n]=null};a._setTextureCleanup({deleteTexture:function(n,q){var u=ja[q];u&&oa[n].ge.deleteTexture(u);ja[q]=null}});a.MakeWebGLContext=function(n){if(!this.Od(n))return null;var q=this._MakeGrContext();if(!q)return null;q.Md=n;var u=q.delete.bind(q);q["delete"]=function(){a.Od(this.Md);u()}.bind(q);return x.ef=q};a.MakeGrContext=a.MakeWebGLContext;a.GrDirectContext.prototype.getResourceCacheLimitBytes=function(){a.Od(this.Md);
this._getResourceCacheLimitBytes()};a.GrDirectContext.prototype.getResourceCacheUsageBytes=function(){a.Od(this.Md);this._getResourceCacheUsageBytes()};a.GrDirectContext.prototype.releaseResourcesAndAbandonContext=function(){a.Od(this.Md);this._releaseResourcesAndAbandonContext()};a.GrDirectContext.prototype.setResourceCacheLimitBytes=function(n){a.Od(this.Md);this._setResourceCacheLimitBytes(n)};a.MakeOnScreenGLSurface=function(n,q,u,D,G,M){if(!this.Od(n.Md))return null;q=void 0===G||void 0===M?
this._MakeOnScreenGLSurface(n,q,u,D):this._MakeOnScreenGLSurface(n,q,u,D,G,M);if(!q)return null;q.Md=n.Md;return q};a.MakeRenderTarget=function(){var n=arguments[0];if(!this.Od(n.Md))return null;if(3===arguments.length){var q=this._MakeRenderTargetWH(n,arguments[1],arguments[2]);if(!q)return null}else if(2===arguments.length){if(q=this._MakeRenderTargetII(n,arguments[1]),!q)return null}else return null;q.Md=n.Md;return q};a.MakeWebGLCanvasSurface=function(n,q,u){q=q||null;var D=n,G="undefined"!==
typeof OffscreenCanvas&&D instanceof OffscreenCanvas;if(!("undefined"!==typeof HTMLCanvasElement&&D instanceof HTMLCanvasElement||G||(D=document.getElementById(n),D)))throw"Canvas with id "+n+" was not found";n=this.GetWebGLContext(D,u);if(!n||0>n)throw"failed to create webgl context: err "+n;n=this.MakeWebGLContext(n);q=this.MakeOnScreenGLSurface(n,D.width,D.height,q);return q?q:(q=D.cloneNode(!0),D.parentNode.replaceChild(q,D),q.classList.add("ck-replaced"),a.MakeSWCanvasSurface(q))};a.MakeCanvasSurface=
a.MakeWebGLCanvasSurface;a.Surface.prototype.makeImageFromTexture=function(n,q){a.Od(this.Md);n=e(n);if(q=this._makeImageFromTexture(this.Md,n,q))q.Le=n;return q};a.Surface.prototype.makeImageFromTextureSource=function(n,q,u){q||(q={height:f(n),width:h(n),colorType:a.ColorType.RGBA_8888,alphaType:u?a.AlphaType.Premul:a.AlphaType.Unpremul});q.colorSpace||(q.colorSpace=a.ColorSpace.SRGB);a.Od(this.Md);var D=x.ge;u=m(D,D.createTexture(),q,u);2===x.version?D.texImage2D(D.TEXTURE_2D,0,D.RGBA,q.width,q.height,
0,D.RGBA,D.UNSIGNED_BYTE,n):D.texImage2D(D.TEXTURE_2D,0,D.RGBA,D.RGBA,D.UNSIGNED_BYTE,n);v(D,q);this._resetContext();return this.makeImageFromTexture(u,q)};a.Surface.prototype.updateTextureFromSource=function(n,q,u){if(n.Le){a.Od(this.Md);var D=n.getImageInfo(),G=x.ge,M=m(G,ja[n.Le],D,u);2===x.version?G.texImage2D(G.TEXTURE_2D,0,G.RGBA,h(q),f(q),0,G.RGBA,G.UNSIGNED_BYTE,q):G.texImage2D(G.TEXTURE_2D,0,G.RGBA,G.RGBA,G.UNSIGNED_BYTE,q);v(G,D,u);this._resetContext();ja[n.Le]=null;n.Le=e(M);D.colorSpace=
n.getColorSpace();q=this._makeImageFromTexture(this.Md,n.Le,D);u=n.Ld.Ud;G=n.Ld.$d;n.Ld.Ud=q.Ld.Ud;n.Ld.$d=q.Ld.$d;q.Ld.Ud=u;q.Ld.$d=G;q.delete();D.colorSpace.delete()}};a.MakeLazyImageFromTextureSource=function(n,q,u){q||(q={height:f(n),width:h(n),colorType:a.ColorType.RGBA_8888,alphaType:u?a.AlphaType.Premul:a.AlphaType.Unpremul});q.colorSpace||(q.colorSpace=a.ColorSpace.SRGB);var D={makeTexture:function(){var G=x,M=G.ge,z=m(M,M.createTexture(),q,u);2===G.version?M.texImage2D(M.TEXTURE_2D,0,M.RGBA,
q.width,q.height,0,M.RGBA,M.UNSIGNED_BYTE,n):M.texImage2D(M.TEXTURE_2D,0,M.RGBA,M.RGBA,M.UNSIGNED_BYTE,n);v(M,q,u);return e(z)},freeSrc:function(){}};"VideoFrame"===n.constructor.name&&(D.freeSrc=function(){n.close()});return a.Image._makeFromGenerator(q,D)};a.Od=function(n){return n?ma(n):!1};a.df=function(){return x&&x.ef&&!x.ef.isDeleted()?x.ef:null}})})(w);
(function(a){function b(d,c,g,l,r){for(var y=0;y<d.length;y++)c[y*g+(y*r+l+g)%g]=d[y];return c}function e(d){for(var c=d*d,g=Array(c);c--;)g[c]=0===c%(d+1)?1:0;return g}function f(d){return d?d.constructor===Float32Array&&4===d.length:!1}function h(d){return(n(255*d[3])<<24|n(255*d[0])<<16|n(255*d[1])<<8|n(255*d[2])<<0)>>>0}function m(d){if(d&&d._ck)return d;if(d instanceof Float32Array){for(var c=Math.floor(d.length/4),g=new Uint32Array(c),l=0;l<c;l++)g[l]=h(d.slice(4*l,4*(l+1)));return g}if(d instanceof
Uint32Array)return d;if(d instanceof Array&&d[0]instanceof Float32Array)return d.map(h)}function v(d){if(void 0===d)return 1;var c=parseFloat(d);return d&&-1!==d.indexOf("%")?c/100:c}function n(d){return Math.round(Math.max(0,Math.min(d||0,255)))}function q(d,c){c&&c._ck||a._free(d)}function u(d,c,g){if(!d||!d.length)return U;if(d&&d._ck)return d.byteOffset;var l=a[c].BYTES_PER_ELEMENT;g||(g=a._malloc(d.length*l));a[c].set(d,g/l);return g}function D(d){var c={ce:U,count:d.length,colorType:a.ColorType.RGBA_F32};
if(d instanceof Float32Array)c.ce=u(d,"HEAPF32"),c.count=d.length/4;else if(d instanceof Uint32Array)c.ce=u(d,"HEAPU32"),c.colorType=a.ColorType.RGBA_8888;else if(d instanceof Array){if(d&&d.length){for(var g=a._malloc(16*d.length),l=0,r=g/4,y=0;y<d.length;y++)for(var B=0;4>B;B++)a.HEAPF32[r+l]=d[y][B],l++;d=g}else d=U;c.ce=d}else throw"Invalid argument to copyFlexibleColorArray, Not a color array "+typeof d;return c}function G(d){if(!d)return U;var c=Ub.toTypedArray();if(d.length){if(6===d.length||
9===d.length)return u(d,"HEAPF32",Oa),6===d.length&&a.HEAPF32.set(yd,6+Oa/4),Oa;if(16===d.length)return c[0]=d[0],c[1]=d[1],c[2]=d[3],c[3]=d[4],c[4]=d[5],c[5]=d[7],c[6]=d[12],c[7]=d[13],c[8]=d[15],Oa;throw"invalid matrix size";}if(void 0===d.m11)throw"invalid matrix argument";c[0]=d.m11;c[1]=d.m21;c[2]=d.m41;c[3]=d.m12;c[4]=d.m22;c[5]=d.m42;c[6]=d.m14;c[7]=d.m24;c[8]=d.m44;return Oa}function M(d){if(!d)return U;var c=Vb.toTypedArray();if(d.length){if(16!==d.length&&6!==d.length&&9!==d.length)throw"invalid matrix size";
if(16===d.length)return u(d,"HEAPF32",ab);c.fill(0);c[0]=d[0];c[1]=d[1];c[3]=d[2];c[4]=d[3];c[5]=d[4];c[7]=d[5];c[10]=1;c[12]=d[6];c[13]=d[7];c[15]=d[8];6===d.length&&(c[12]=0,c[13]=0,c[15]=1);return ab}if(void 0===d.m11)throw"invalid matrix argument";c[0]=d.m11;c[1]=d.m21;c[2]=d.m31;c[3]=d.m41;c[4]=d.m12;c[5]=d.m22;c[6]=d.m32;c[7]=d.m42;c[8]=d.m13;c[9]=d.m23;c[10]=d.m33;c[11]=d.m43;c[12]=d.m14;c[13]=d.m24;c[14]=d.m34;c[15]=d.m44;return ab}function z(d,c){return u(d,"HEAPF32",c||Ua)}function P(d,
c,g,l){var r=Wb.toTypedArray();r[0]=d;r[1]=c;r[2]=g;r[3]=l;return Ua}function V(d){for(var c=new Float32Array(4),g=0;4>g;g++)c[g]=a.HEAPF32[d/4+g];return c}function S(d,c){return u(d,"HEAPF32",c||ha)}function ra(d,c){return u(d,"HEAPF32",c||Xb)}function ya(){for(var d=0,c=0;c<arguments.length-1;c+=2)d+=arguments[c]*arguments[c+1];return d}function gb(d,c,g){for(var l=Array(d.length),r=0;r<g;r++)for(var y=0;y<g;y++){for(var B=0,J=0;J<g;J++)B+=d[g*r+J]*c[g*J+y];l[r*g+y]=B}return l}function hb(d,c){for(var g=
gb(c[0],c[1],d),l=2;l<c.length;)g=gb(g,c[l],d),l++;return g}a.Color=function(d,c,g,l){void 0===l&&(l=1);return a.Color4f(n(d)/255,n(c)/255,n(g)/255,l)};a.ColorAsInt=function(d,c,g,l){void 0===l&&(l=255);return(n(l)<<24|n(d)<<16|n(c)<<8|n(g)<<0&268435455)>>>0};a.Color4f=function(d,c,g,l){void 0===l&&(l=1);return Float32Array.of(d,c,g,l)};Object.defineProperty(a,"TRANSPARENT",{get:function(){return a.Color4f(0,0,0,0)}});Object.defineProperty(a,"BLACK",{get:function(){return a.Color4f(0,0,0,1)}});Object.defineProperty(a,
"WHITE",{get:function(){return a.Color4f(1,1,1,1)}});Object.defineProperty(a,"RED",{get:function(){return a.Color4f(1,0,0,1)}});Object.defineProperty(a,"GREEN",{get:function(){return a.Color4f(0,1,0,1)}});Object.defineProperty(a,"BLUE",{get:function(){return a.Color4f(0,0,1,1)}});Object.defineProperty(a,"YELLOW",{get:function(){return a.Color4f(1,1,0,1)}});Object.defineProperty(a,"CYAN",{get:function(){return a.Color4f(0,1,1,1)}});Object.defineProperty(a,"MAGENTA",{get:function(){return a.Color4f(1,
0,1,1)}});a.getColorComponents=function(d){return[Math.floor(255*d[0]),Math.floor(255*d[1]),Math.floor(255*d[2]),d[3]]};a.parseColorString=function(d,c){d=d.toLowerCase();if(d.startsWith("#")){c=255;switch(d.length){case 9:c=parseInt(d.slice(7,9),16);case 7:var g=parseInt(d.slice(1,3),16);var l=parseInt(d.slice(3,5),16);var r=parseInt(d.slice(5,7),16);break;case 5:c=17*parseInt(d.slice(4,5),16);case 4:g=17*parseInt(d.slice(1,2),16),l=17*parseInt(d.slice(2,3),16),r=17*parseInt(d.slice(3,4),16)}return a.Color(g,
l,r,c/255)}return d.startsWith("rgba")?(d=d.slice(5,-1),d=d.split(","),a.Color(+d[0],+d[1],+d[2],v(d[3]))):d.startsWith("rgb")?(d=d.slice(4,-1),d=d.split(","),a.Color(+d[0],+d[1],+d[2],v(d[3]))):d.startsWith("gray(")||d.startsWith("hsl")||!c||(d=c[d],void 0===d)?a.BLACK:d};a.multiplyByAlpha=function(d,c){d=d.slice();d[3]=Math.max(0,Math.min(d[3]*c,1));return d};a.Malloc=function(d,c){var g=a._malloc(c*d.BYTES_PER_ELEMENT);return{_ck:!0,length:c,byteOffset:g,re:null,subarray:function(l,r){l=this.toTypedArray().subarray(l,
r);l._ck=!0;return l},toTypedArray:function(){if(this.re&&this.re.length)return this.re;this.re=new d(a.HEAPU8.buffer,g,c);this.re._ck=!0;return this.re}}};a.Free=function(d){a._free(d.byteOffset);d.byteOffset=U;d.toTypedArray=null;d.re=null};var Oa=U,Ub,ab=U,Vb,Ua=U,Wb,sa,ha=U,Cc,Pa=U,Dc,Yb=U,Ec,Zb=U,$b,xb=U,Fc,Xb=U,Gc,Hc=U,yd=Float32Array.of(0,0,1),U=0;a.onRuntimeInitialized=function(){function d(c,g,l,r,y,B,J){B||(B=4*r.width,r.colorType===a.ColorType.RGBA_F16?B*=2:r.colorType===a.ColorType.RGBA_F32&&
(B*=4));var O=B*r.height;var L=y?y.byteOffset:a._malloc(O);if(J?!c._readPixels(r,L,B,g,l,J):!c._readPixels(r,L,B,g,l))return y||a._free(L),null;if(y)return y.toTypedArray();switch(r.colorType){case a.ColorType.RGBA_8888:case a.ColorType.RGBA_F16:c=(new Uint8Array(a.HEAPU8.buffer,L,O)).slice();break;case a.ColorType.RGBA_F32:c=(new Float32Array(a.HEAPU8.buffer,L,O)).slice();break;default:return null}a._free(L);return c}Wb=a.Malloc(Float32Array,4);Ua=Wb.byteOffset;Vb=a.Malloc(Float32Array,16);ab=Vb.byteOffset;
Ub=a.Malloc(Float32Array,9);Oa=Ub.byteOffset;Fc=a.Malloc(Float32Array,12);Xb=Fc.byteOffset;Gc=a.Malloc(Float32Array,12);Hc=Gc.byteOffset;sa=a.Malloc(Float32Array,4);ha=sa.byteOffset;Cc=a.Malloc(Float32Array,4);Pa=Cc.byteOffset;Dc=a.Malloc(Float32Array,3);Yb=Dc.byteOffset;Ec=a.Malloc(Float32Array,3);Zb=Ec.byteOffset;$b=a.Malloc(Int32Array,4);xb=$b.byteOffset;a.ColorSpace.SRGB=a.ColorSpace._MakeSRGB();a.ColorSpace.DISPLAY_P3=a.ColorSpace._MakeDisplayP3();a.ColorSpace.ADOBE_RGB=a.ColorSpace._MakeAdobeRGB();
a.GlyphRunFlags={IsWhiteSpace:a._GlyphRunFlags_isWhiteSpace};a.Path.MakeFromCmds=function(c){var g=u(c,"HEAPF32"),l=a.Path._MakeFromCmds(g,c.length);q(g,c);return l};a.Path.MakeFromVerbsPointsWeights=function(c,g,l){var r=u(c,"HEAPU8"),y=u(g,"HEAPF32"),B=u(l,"HEAPF32"),J=a.Path._MakeFromVerbsPointsWeights(r,c.length,y,g.length,B,l&&l.length||0);q(r,c);q(y,g);q(B,l);return J};a.Path.prototype.addArc=function(c,g,l){c=S(c);this._addArc(c,g,l);return this};a.Path.prototype.addCircle=function(c,g,l,r){this._addCircle(c,
g,l,!!r);return this};a.Path.prototype.addOval=function(c,g,l){void 0===l&&(l=1);c=S(c);this._addOval(c,!!g,l);return this};a.Path.prototype.addPath=function(){var c=Array.prototype.slice.call(arguments),g=c[0],l=!1;"boolean"===typeof c[c.length-1]&&(l=c.pop());if(1===c.length)this._addPath(g,1,0,0,0,1,0,0,0,1,l);else if(2===c.length)c=c[1],this._addPath(g,c[0],c[1],c[2],c[3],c[4],c[5],c[6]||0,c[7]||0,c[8]||1,l);else if(7===c.length||10===c.length)this._addPath(g,c[1],c[2],c[3],c[4],c[5],c[6],c[7]||
0,c[8]||0,c[9]||1,l);else return null;return this};a.Path.prototype.addPoly=function(c,g){var l=u(c,"HEAPF32");this._addPoly(l,c.length/2,g);q(l,c);return this};a.Path.prototype.addRect=function(c,g){c=S(c);this._addRect(c,!!g);return this};a.Path.prototype.addRRect=function(c,g){c=ra(c);this._addRRect(c,!!g);return this};a.Path.prototype.addVerbsPointsWeights=function(c,g,l){var r=u(c,"HEAPU8"),y=u(g,"HEAPF32"),B=u(l,"HEAPF32");this._addVerbsPointsWeights(r,c.length,y,g.length,B,l&&l.length||0);
q(r,c);q(y,g);q(B,l)};a.Path.prototype.arc=function(c,g,l,r,y,B){c=a.LTRBRect(c-l,g-l,c+l,g+l);y=(y-r)/Math.PI*180-360*!!B;B=new a.Path;B.addArc(c,r/Math.PI*180,y);this.addPath(B,!0);B.delete();return this};a.Path.prototype.arcToOval=function(c,g,l,r){c=S(c);this._arcToOval(c,g,l,r);return this};a.Path.prototype.arcToRotated=function(c,g,l,r,y,B,J){this._arcToRotated(c,g,l,!!r,!!y,B,J);return this};a.Path.prototype.arcToTangent=function(c,g,l,r,y){this._arcToTangent(c,g,l,r,y);return this};a.Path.prototype.close=
function(){this._close();return this};a.Path.prototype.conicTo=function(c,g,l,r,y){this._conicTo(c,g,l,r,y);return this};a.Path.prototype.computeTightBounds=function(c){this._computeTightBounds(ha);var g=sa.toTypedArray();return c?(c.set(g),c):g.slice()};a.Path.prototype.cubicTo=function(c,g,l,r,y,B){this._cubicTo(c,g,l,r,y,B);return this};a.Path.prototype.dash=function(c,g,l){return this._dash(c,g,l)?this:null};a.Path.prototype.getBounds=function(c){this._getBounds(ha);var g=sa.toTypedArray();return c?
(c.set(g),c):g.slice()};a.Path.prototype.lineTo=function(c,g){this._lineTo(c,g);return this};a.Path.prototype.moveTo=function(c,g){this._moveTo(c,g);return this};a.Path.prototype.offset=function(c,g){this._transform(1,0,c,0,1,g,0,0,1);return this};a.Path.prototype.quadTo=function(c,g,l,r){this._quadTo(c,g,l,r);return this};a.Path.prototype.rArcTo=function(c,g,l,r,y,B,J){this._rArcTo(c,g,l,r,y,B,J);return this};a.Path.prototype.rConicTo=function(c,g,l,r,y){this._rConicTo(c,g,l,r,y);return this};a.Path.prototype.rCubicTo=
function(c,g,l,r,y,B){this._rCubicTo(c,g,l,r,y,B);return this};a.Path.prototype.rLineTo=function(c,g){this._rLineTo(c,g);return this};a.Path.prototype.rMoveTo=function(c,g){this._rMoveTo(c,g);return this};a.Path.prototype.rQuadTo=function(c,g,l,r){this._rQuadTo(c,g,l,r);return this};a.Path.prototype.stroke=function(c){c=c||{};c.width=c.width||1;c.miter_limit=c.miter_limit||4;c.cap=c.cap||a.StrokeCap.Butt;c.join=c.join||a.StrokeJoin.Miter;c.precision=c.precision||1;return this._stroke(c)?this:null};
a.Path.prototype.transform=function(){if(1===arguments.length){var c=arguments[0];this._transform(c[0],c[1],c[2],c[3],c[4],c[5],c[6]||0,c[7]||0,c[8]||1)}else if(6===arguments.length||9===arguments.length)c=arguments,this._transform(c[0],c[1],c[2],c[3],c[4],c[5],c[6]||0,c[7]||0,c[8]||1);else throw"transform expected to take 1 or 9 arguments. Got "+arguments.length;return this};a.Path.prototype.trim=function(c,g,l){return this._trim(c,g,!!l)?this:null};a.Image.prototype.makeShaderCubic=function(c,g,
l,r,y){y=G(y);return this._makeShaderCubic(c,g,l,r,y)};a.Image.prototype.makeShaderOptions=function(c,g,l,r,y){y=G(y);return this._makeShaderOptions(c,g,l,r,y)};a.Image.prototype.readPixels=function(c,g,l,r,y){var B=a.df();return d(this,c,g,l,r,y,B)};a.Canvas.prototype.clear=function(c){a.Od(this.Md);c=z(c);this._clear(c)};a.Canvas.prototype.clipRRect=function(c,g,l){a.Od(this.Md);c=ra(c);this._clipRRect(c,g,l)};a.Canvas.prototype.clipRect=function(c,g,l){a.Od(this.Md);c=S(c);this._clipRect(c,g,l)};
a.Canvas.prototype.concat=function(c){a.Od(this.Md);c=M(c);this._concat(c)};a.Canvas.prototype.drawArc=function(c,g,l,r,y){a.Od(this.Md);c=S(c);this._drawArc(c,g,l,r,y)};a.Canvas.prototype.drawAtlas=function(c,g,l,r,y,B,J){if(c&&r&&g&&l&&g.length===l.length){a.Od(this.Md);y||(y=a.BlendMode.SrcOver);var O=u(g,"HEAPF32"),L=u(l,"HEAPF32"),W=l.length/4,t=u(m(B),"HEAPU32");if(J&&"B"in J&&"C"in J)this._drawAtlasCubic(c,L,O,t,W,y,J.B,J.C,r);else{let F=a.FilterMode.Linear,Q=a.MipmapMode.None;J&&(F=J.filter,
"mipmap"in J&&(Q=J.mipmap));this._drawAtlasOptions(c,L,O,t,W,y,F,Q,r)}q(O,g);q(L,l);q(t,B)}};a.Canvas.prototype.drawCircle=function(c,g,l,r){a.Od(this.Md);this._drawCircle(c,g,l,r)};a.Canvas.prototype.drawColor=function(c,g){a.Od(this.Md);c=z(c);void 0!==g?this._drawColor(c,g):this._drawColor(c)};a.Canvas.prototype.drawColorInt=function(c,g){a.Od(this.Md);this._drawColorInt(c,g||a.BlendMode.SrcOver)};a.Canvas.prototype.drawColorComponents=function(c,g,l,r,y){a.Od(this.Md);c=P(c,g,l,r);void 0!==y?
this._drawColor(c,y):this._drawColor(c)};a.Canvas.prototype.drawDRRect=function(c,g,l){a.Od(this.Md);c=ra(c,Xb);g=ra(g,Hc);this._drawDRRect(c,g,l)};a.Canvas.prototype.drawImage=function(c,g,l,r){a.Od(this.Md);this._drawImage(c,g,l,r||null)};a.Canvas.prototype.drawImageCubic=function(c,g,l,r,y,B){a.Od(this.Md);this._drawImageCubic(c,g,l,r,y,B||null)};a.Canvas.prototype.drawImageOptions=function(c,g,l,r,y,B){a.Od(this.Md);this._drawImageOptions(c,g,l,r,y,B||null)};a.Canvas.prototype.drawImageNine=function(c,
g,l,r,y){a.Od(this.Md);g=u(g,"HEAP32",xb);l=S(l);this._drawImageNine(c,g,l,r,y||null)};a.Canvas.prototype.drawImageRect=function(c,g,l,r,y){a.Od(this.Md);S(g,ha);S(l,Pa);this._drawImageRect(c,ha,Pa,r,!!y)};a.Canvas.prototype.drawImageRectCubic=function(c,g,l,r,y,B){a.Od(this.Md);S(g,ha);S(l,Pa);this._drawImageRectCubic(c,ha,Pa,r,y,B||null)};a.Canvas.prototype.drawImageRectOptions=function(c,g,l,r,y,B){a.Od(this.Md);S(g,ha);S(l,Pa);this._drawImageRectOptions(c,ha,Pa,r,y,B||null)};a.Canvas.prototype.drawLine=
function(c,g,l,r,y){a.Od(this.Md);this._drawLine(c,g,l,r,y)};a.Canvas.prototype.drawOval=function(c,g){a.Od(this.Md);c=S(c);this._drawOval(c,g)};a.Canvas.prototype.drawPaint=function(c){a.Od(this.Md);this._drawPaint(c)};a.Canvas.prototype.drawParagraph=function(c,g,l){a.Od(this.Md);this._drawParagraph(c,g,l)};a.Canvas.prototype.drawPatch=function(c,g,l,r,y){if(24>c.length)throw"Need 12 cubic points";if(g&&4>g.length)throw"Need 4 colors";if(l&&8>l.length)throw"Need 4 shader coordinates";a.Od(this.Md);
const B=u(c,"HEAPF32"),J=g?u(m(g),"HEAPU32"):U,O=l?u(l,"HEAPF32"):U;r||(r=a.BlendMode.Modulate);this._drawPatch(B,J,O,r,y);q(O,l);q(J,g);q(B,c)};a.Canvas.prototype.drawPath=function(c,g){a.Od(this.Md);this._drawPath(c,g)};a.Canvas.prototype.drawPicture=function(c){a.Od(this.Md);this._drawPicture(c)};a.Canvas.prototype.drawPoints=function(c,g,l){a.Od(this.Md);var r=u(g,"HEAPF32");this._drawPoints(c,r,g.length/2,l);q(r,g)};a.Canvas.prototype.drawRRect=function(c,g){a.Od(this.Md);c=ra(c);this._drawRRect(c,
g)};a.Canvas.prototype.drawRect=function(c,g){a.Od(this.Md);c=S(c);this._drawRect(c,g)};a.Canvas.prototype.drawRect4f=function(c,g,l,r,y){a.Od(this.Md);this._drawRect4f(c,g,l,r,y)};a.Canvas.prototype.drawShadow=function(c,g,l,r,y,B,J){a.Od(this.Md);var O=u(y,"HEAPF32"),L=u(B,"HEAPF32");g=u(g,"HEAPF32",Yb);l=u(l,"HEAPF32",Zb);this._drawShadow(c,g,l,r,O,L,J);q(O,y);q(L,B)};a.getShadowLocalBounds=function(c,g,l,r,y,B,J){c=G(c);l=u(l,"HEAPF32",Yb);r=u(r,"HEAPF32",Zb);if(!this._getShadowLocalBounds(c,
g,l,r,y,B,ha))return null;g=sa.toTypedArray();return J?(J.set(g),J):g.slice()};a.Canvas.prototype.drawTextBlob=function(c,g,l,r){a.Od(this.Md);this._drawTextBlob(c,g,l,r)};a.Canvas.prototype.drawVertices=function(c,g,l){a.Od(this.Md);this._drawVertices(c,g,l)};a.Canvas.prototype.getDeviceClipBounds=function(c){this._getDeviceClipBounds(xb);var g=$b.toTypedArray();c?c.set(g):c=g.slice();return c};a.Canvas.prototype.getLocalToDevice=function(){this._getLocalToDevice(ab);for(var c=ab,g=Array(16),l=0;16>
l;l++)g[l]=a.HEAPF32[c/4+l];return g};a.Canvas.prototype.getTotalMatrix=function(){this._getTotalMatrix(Oa);for(var c=Array(9),g=0;9>g;g++)c[g]=a.HEAPF32[Oa/4+g];return c};a.Canvas.prototype.makeSurface=function(c){c=this._makeSurface(c);c.Md=this.Md;return c};a.Canvas.prototype.readPixels=function(c,g,l,r,y){a.Od(this.Md);return d(this,c,g,l,r,y)};a.Canvas.prototype.saveLayer=function(c,g,l,r){g=S(g);return this._saveLayer(c||null,g,l||null,r||0)};a.Canvas.prototype.writePixels=function(c,g,l,r,
y,B,J,O){if(c.byteLength%(g*l))throw"pixels length must be a multiple of the srcWidth * srcHeight";a.Od(this.Md);var L=c.byteLength/(g*l);B=B||a.AlphaType.Unpremul;J=J||a.ColorType.RGBA_8888;O=O||a.ColorSpace.SRGB;var W=L*g;L=u(c,"HEAPU8");g=this._writePixels({width:g,height:l,colorType:J,alphaType:B,colorSpace:O},L,W,r,y);q(L,c);return g};a.ColorFilter.MakeBlend=function(c,g,l){c=z(c);l=l||a.ColorSpace.SRGB;return a.ColorFilter._MakeBlend(c,g,l)};a.ColorFilter.MakeMatrix=function(c){if(!c||20!==
c.length)throw"invalid color matrix";var g=u(c,"HEAPF32"),l=a.ColorFilter._makeMatrix(g);q(g,c);return l};a.ContourMeasure.prototype.getPosTan=function(c,g){this._getPosTan(c,ha);c=sa.toTypedArray();return g?(g.set(c),g):c.slice()};a.ImageFilter.MakeDropShadow=function(c,g,l,r,y,B){y=z(y,Ua);return a.ImageFilter._MakeDropShadow(c,g,l,r,y,B)};a.ImageFilter.MakeDropShadowOnly=function(c,g,l,r,y,B){y=z(y,Ua);return a.ImageFilter._MakeDropShadowOnly(c,g,l,r,y,B)};a.ImageFilter.MakeImage=function(c,g,
l,r){l=S(l,ha);r=S(r,Pa);if("B"in g&&"C"in g)return a.ImageFilter._MakeImageCubic(c,g.B,g.C,l,r);const y=g.filter;let B=a.MipmapMode.None;"mipmap"in g&&(B=g.mipmap);return a.ImageFilter._MakeImageOptions(c,y,B,l,r)};a.ImageFilter.MakeMatrixTransform=function(c,g,l){c=G(c);if("B"in g&&"C"in g)return a.ImageFilter._MakeMatrixTransformCubic(c,g.B,g.C,l);const r=g.filter;let y=a.MipmapMode.None;"mipmap"in g&&(y=g.mipmap);return a.ImageFilter._MakeMatrixTransformOptions(c,r,y,l)};a.Paint.prototype.getColor=
function(){this._getColor(Ua);return V(Ua)};a.Paint.prototype.setColor=function(c,g){g=g||null;c=z(c);this._setColor(c,g)};a.Paint.prototype.setColorComponents=function(c,g,l,r,y){y=y||null;c=P(c,g,l,r);this._setColor(c,y)};a.Path.prototype.getPoint=function(c,g){this._getPoint(c,ha);c=sa.toTypedArray();return g?(g[0]=c[0],g[1]=c[1],g):c.slice(0,2)};a.Picture.prototype.makeShader=function(c,g,l,r,y){r=G(r);y=S(y);return this._makeShader(c,g,l,r,y)};a.PictureRecorder.prototype.beginRecording=function(c){c=
S(c);return this._beginRecording(c)};a.Surface.prototype.getCanvas=function(){var c=this._getCanvas();c.Md=this.Md;return c};a.Surface.prototype.makeImageSnapshot=function(c){a.Od(this.Md);c=u(c,"HEAP32",xb);return this._makeImageSnapshot(c)};a.Surface.prototype.makeSurface=function(c){a.Od(this.Md);c=this._makeSurface(c);c.Md=this.Md;return c};a.Surface.prototype.Df=function(c,g){this.He||(this.He=this.getCanvas());return requestAnimationFrame(function(){a.Od(this.Md);c(this.He);this.flush(g)}.bind(this))};
a.Surface.prototype.requestAnimationFrame||(a.Surface.prototype.requestAnimationFrame=a.Surface.prototype.Df);a.Surface.prototype.zf=function(c,g){this.He||(this.He=this.getCanvas());requestAnimationFrame(function(){a.Od(this.Md);c(this.He);this.flush(g);this.dispose()}.bind(this))};a.Surface.prototype.drawOnce||(a.Surface.prototype.drawOnce=a.Surface.prototype.zf);a.PathEffect.MakeDash=function(c,g){g||(g=0);if(!c.length||1===c.length%2)throw"Intervals array must have even length";var l=u(c,"HEAPF32");
g=a.PathEffect._MakeDash(l,c.length,g);q(l,c);return g};a.PathEffect.MakeLine2D=function(c,g){g=G(g);return a.PathEffect._MakeLine2D(c,g)};a.PathEffect.MakePath2D=function(c,g){c=G(c);return a.PathEffect._MakePath2D(c,g)};a.Shader.MakeColor=function(c,g){g=g||null;c=z(c);return a.Shader._MakeColor(c,g)};a.Shader.Blend=a.Shader.MakeBlend;a.Shader.Color=a.Shader.MakeColor;a.Shader.MakeLinearGradient=function(c,g,l,r,y,B,J,O){O=O||null;var L=D(l),W=u(r,"HEAPF32");J=J||0;B=G(B);var t=sa.toTypedArray();
t.set(c);t.set(g,2);c=a.Shader._MakeLinearGradient(ha,L.ce,L.colorType,W,L.count,y,J,B,O);q(L.ce,l);r&&q(W,r);return c};a.Shader.MakeRadialGradient=function(c,g,l,r,y,B,J,O){O=O||null;var L=D(l),W=u(r,"HEAPF32");J=J||0;B=G(B);c=a.Shader._MakeRadialGradient(c[0],c[1],g,L.ce,L.colorType,W,L.count,y,J,B,O);q(L.ce,l);r&&q(W,r);return c};a.Shader.MakeSweepGradient=function(c,g,l,r,y,B,J,O,L,W){W=W||null;var t=D(l),F=u(r,"HEAPF32");J=J||0;O=O||0;L=L||360;B=G(B);c=a.Shader._MakeSweepGradient(c,g,t.ce,t.colorType,
F,t.count,y,O,L,J,B,W);q(t.ce,l);r&&q(F,r);return c};a.Shader.MakeTwoPointConicalGradient=function(c,g,l,r,y,B,J,O,L,W){W=W||null;var t=D(y),F=u(B,"HEAPF32");L=L||0;O=G(O);var Q=sa.toTypedArray();Q.set(c);Q.set(l,2);c=a.Shader._MakeTwoPointConicalGradient(ha,g,r,t.ce,t.colorType,F,t.count,J,L,O,W);q(t.ce,y);B&&q(F,B);return c};a.Vertices.prototype.bounds=function(c){this._bounds(ha);var g=sa.toTypedArray();return c?(c.set(g),c):g.slice()};a.Rd&&a.Rd.forEach(function(c){c()})};a.computeTonalColors=
function(d){var c=u(d.ambient,"HEAPF32"),g=u(d.spot,"HEAPF32");this._computeTonalColors(c,g);var l={ambient:V(c),spot:V(g)};q(c,d.ambient);q(g,d.spot);return l};a.LTRBRect=function(d,c,g,l){return Float32Array.of(d,c,g,l)};a.XYWHRect=function(d,c,g,l){return Float32Array.of(d,c,d+g,c+l)};a.LTRBiRect=function(d,c,g,l){return Int32Array.of(d,c,g,l)};a.XYWHiRect=function(d,c,g,l){return Int32Array.of(d,c,d+g,c+l)};a.RRectXY=function(d,c,g){return Float32Array.of(d[0],d[1],d[2],d[3],c,g,c,g,c,g,c,g)};
a.MakeAnimatedImageFromEncoded=function(d){d=new Uint8Array(d);var c=a._malloc(d.byteLength);a.HEAPU8.set(d,c);return(d=a._decodeAnimatedImage(c,d.byteLength))?d:null};a.MakeImageFromEncoded=function(d){d=new Uint8Array(d);var c=a._malloc(d.byteLength);a.HEAPU8.set(d,c);return(d=a._decodeImage(c,d.byteLength))?d:null};var ib=null;a.MakeImageFromCanvasImageSource=function(d){var c=d.width,g=d.height;ib||(ib=document.createElement("canvas"));ib.width=c;ib.height=g;var l=ib.getContext("2d",{wg:!0});
l.drawImage(d,0,0);d=l.getImageData(0,0,c,g);return a.MakeImage({width:c,height:g,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},d.data,4*c)};a.MakeImage=function(d,c,g){var l=a._malloc(c.length);a.HEAPU8.set(c,l);return a._MakeImage(d,l,c.length,g)};a.MakeVertices=function(d,c,g,l,r,y){var B=r&&r.length||0,J=0;g&&g.length&&(J|=1);l&&l.length&&(J|=2);void 0===y||y||(J|=4);d=new a._VerticesBuilder(d,c.length/2,B,J);u(c,"HEAPF32",d.positions());d.texCoords()&&
u(g,"HEAPF32",d.texCoords());d.colors()&&u(m(l),"HEAPU32",d.colors());d.indices()&&u(r,"HEAPU16",d.indices());return d.detach()};a.Matrix={};a.Matrix.identity=function(){return e(3)};a.Matrix.invert=function(d){var c=d[0]*d[4]*d[8]+d[1]*d[5]*d[6]+d[2]*d[3]*d[7]-d[2]*d[4]*d[6]-d[1]*d[3]*d[8]-d[0]*d[5]*d[7];return c?[(d[4]*d[8]-d[5]*d[7])/c,(d[2]*d[7]-d[1]*d[8])/c,(d[1]*d[5]-d[2]*d[4])/c,(d[5]*d[6]-d[3]*d[8])/c,(d[0]*d[8]-d[2]*d[6])/c,(d[2]*d[3]-d[0]*d[5])/c,(d[3]*d[7]-d[4]*d[6])/c,(d[1]*d[6]-d[0]*
d[7])/c,(d[0]*d[4]-d[1]*d[3])/c]:null};a.Matrix.mapPoints=function(d,c){for(var g=0;g<c.length;g+=2){var l=c[g],r=c[g+1],y=d[6]*l+d[7]*r+d[8],B=d[3]*l+d[4]*r+d[5];c[g]=(d[0]*l+d[1]*r+d[2])/y;c[g+1]=B/y}return c};a.Matrix.multiply=function(){return hb(3,arguments)};a.Matrix.rotated=function(d,c,g){c=c||0;g=g||0;var l=Math.sin(d);d=Math.cos(d);return[d,-l,ya(l,g,1-d,c),l,d,ya(-l,c,1-d,g),0,0,1]};a.Matrix.scaled=function(d,c,g,l){g=g||0;l=l||0;var r=b([d,c],e(3),3,0,1);return b([g-d*g,l-c*l],r,3,2,0)};
a.Matrix.skewed=function(d,c,g,l){g=g||0;l=l||0;var r=b([d,c],e(3),3,1,-1);return b([-d*g,-c*l],r,3,2,0)};a.Matrix.translated=function(d,c){return b(arguments,e(3),3,2,0)};a.Vector={};a.Vector.dot=function(d,c){return d.map(function(g,l){return g*c[l]}).reduce(function(g,l){return g+l})};a.Vector.lengthSquared=function(d){return a.Vector.dot(d,d)};a.Vector.length=function(d){return Math.sqrt(a.Vector.lengthSquared(d))};a.Vector.mulScalar=function(d,c){return d.map(function(g){return g*c})};a.Vector.add=
function(d,c){return d.map(function(g,l){return g+c[l]})};a.Vector.sub=function(d,c){return d.map(function(g,l){return g-c[l]})};a.Vector.dist=function(d,c){return a.Vector.length(a.Vector.sub(d,c))};a.Vector.normalize=function(d){return a.Vector.mulScalar(d,1/a.Vector.length(d))};a.Vector.cross=function(d,c){return[d[1]*c[2]-d[2]*c[1],d[2]*c[0]-d[0]*c[2],d[0]*c[1]-d[1]*c[0]]};a.M44={};a.M44.identity=function(){return e(4)};a.M44.translated=function(d){return b(d,e(4),4,3,0)};a.M44.scaled=function(d){return b(d,
e(4),4,0,1)};a.M44.rotated=function(d,c){return a.M44.rotatedUnitSinCos(a.Vector.normalize(d),Math.sin(c),Math.cos(c))};a.M44.rotatedUnitSinCos=function(d,c,g){var l=d[0],r=d[1];d=d[2];var y=1-g;return[y*l*l+g,y*l*r-c*d,y*l*d+c*r,0,y*l*r+c*d,y*r*r+g,y*r*d-c*l,0,y*l*d-c*r,y*r*d+c*l,y*d*d+g,0,0,0,0,1]};a.M44.lookat=function(d,c,g){c=a.Vector.normalize(a.Vector.sub(c,d));g=a.Vector.normalize(g);g=a.Vector.normalize(a.Vector.cross(c,g));var l=a.M44.identity();b(g,l,4,0,0);b(a.Vector.cross(g,c),l,4,1,
0);b(a.Vector.mulScalar(c,-1),l,4,2,0);b(d,l,4,3,0);d=a.M44.invert(l);return null===d?a.M44.identity():d};a.M44.perspective=function(d,c,g){var l=1/(c-d);g/=2;g=Math.cos(g)/Math.sin(g);return[g,0,0,0,0,g,0,0,0,0,(c+d)*l,2*c*d*l,0,0,-1,1]};a.M44.rc=function(d,c,g){return d[4*c+g]};a.M44.multiply=function(){return hb(4,arguments)};a.M44.invert=function(d){var c=d[0],g=d[4],l=d[8],r=d[12],y=d[1],B=d[5],J=d[9],O=d[13],L=d[2],W=d[6],t=d[10],F=d[14],Q=d[3],Y=d[7],ia=d[11];d=d[15];var ka=c*B-g*y,na=c*J-
l*y,ta=c*O-r*y,ba=g*J-l*B,I=g*O-r*B,k=l*O-r*J,p=L*Y-W*Q,A=L*ia-t*Q,C=L*d-F*Q,E=W*ia-t*Y,H=W*d-F*Y,N=t*d-F*ia,ca=ka*N-na*H+ta*E+ba*C-I*A+k*p,da=1/ca;if(0===ca||Infinity===da)return null;ka*=da;na*=da;ta*=da;ba*=da;I*=da;k*=da;p*=da;A*=da;C*=da;E*=da;H*=da;N*=da;c=[B*N-J*H+O*E,J*C-y*N-O*A,y*H-B*C+O*p,B*A-y*E-J*p,l*H-g*N-r*E,c*N-l*C+r*A,g*C-c*H-r*p,c*E-g*A+l*p,Y*k-ia*I+d*ba,ia*ta-Q*k-d*na,Q*I-Y*ta+d*ka,Y*na-Q*ba-ia*ka,t*I-W*k-F*ba,L*k-t*ta+F*na,W*ta-L*I-F*ka,L*ba-W*na+t*ka];return c.every(function(Ia){return!isNaN(Ia)&&
Infinity!==Ia&&-Infinity!==Ia})?c:null};a.M44.transpose=function(d){return[d[0],d[4],d[8],d[12],d[1],d[5],d[9],d[13],d[2],d[6],d[10],d[14],d[3],d[7],d[11],d[15]]};a.M44.mustInvert=function(d){d=a.M44.invert(d);if(null===d)throw"Matrix not invertible";return d};a.M44.setupCamera=function(d,c,g){var l=a.M44.lookat(g.eye,g.coa,g.up);g=a.M44.perspective(g.near,g.far,g.angle);c=[(d[2]-d[0])/2,(d[3]-d[1])/2,c];d=a.M44.multiply(a.M44.translated([(d[0]+d[2])/2,(d[1]+d[3])/2,0]),a.M44.scaled(c));return a.M44.multiply(d,
g,l,a.M44.mustInvert(d))};a.ColorMatrix={};a.ColorMatrix.identity=function(){var d=new Float32Array(20);d[0]=1;d[6]=1;d[12]=1;d[18]=1;return d};a.ColorMatrix.scaled=function(d,c,g,l){var r=new Float32Array(20);r[0]=d;r[6]=c;r[12]=g;r[18]=l;return r};var zd=[[6,7,11,12],[0,10,2,12],[0,1,5,6]];a.ColorMatrix.rotated=function(d,c,g){var l=a.ColorMatrix.identity();d=zd[d];l[d[0]]=g;l[d[1]]=c;l[d[2]]=-c;l[d[3]]=g;return l};a.ColorMatrix.postTranslate=function(d,c,g,l,r){d[4]+=c;d[9]+=g;d[14]+=l;d[19]+=
r;return d};a.ColorMatrix.concat=function(d,c){for(var g=new Float32Array(20),l=0,r=0;20>r;r+=5){for(var y=0;4>y;y++)g[l++]=d[r]*c[y]+d[r+1]*c[y+5]+d[r+2]*c[y+10]+d[r+3]*c[y+15];g[l++]=d[r]*c[4]+d[r+1]*c[9]+d[r+2]*c[14]+d[r+3]*c[19]+d[r+4]}return g};(function(d){d.Rd=d.Rd||[];d.Rd.push(function(){function c(t){if(!t||!t.length)return[];for(var F=[],Q=0;Q<t.length;Q+=5){var Y=d.LTRBRect(t[Q],t[Q+1],t[Q+2],t[Q+3]),ia=d.TextDirection.LTR;0===t[Q+4]&&(ia=d.TextDirection.RTL);F.push({rect:Y,dir:ia})}d._free(t.byteOffset);
return F}function g(t){t=t||{};void 0===t.weight&&(t.weight=d.FontWeight.Normal);t.width=t.width||d.FontWidth.Normal;t.slant=t.slant||d.FontSlant.Upright;return t}function l(t){if(!t||!t.length)return U;for(var F=[],Q=0;Q<t.length;Q++){var Y=r(t[Q]);F.push(Y)}return u(F,"HEAPU32")}function r(t){if(J[t])return J[t];var F=pa(t)+1,Q=d._malloc(F);qa(t,K,Q,F);return J[t]=Q}function y(t){t._colorPtr=z(t.color);t._foregroundColorPtr=U;t._backgroundColorPtr=U;t._decorationColorPtr=U;t.foregroundColor&&(t._foregroundColorPtr=
z(t.foregroundColor,O));t.backgroundColor&&(t._backgroundColorPtr=z(t.backgroundColor,L));t.decorationColor&&(t._decorationColorPtr=z(t.decorationColor,W));Array.isArray(t.fontFamilies)&&t.fontFamilies.length?(t._fontFamiliesPtr=l(t.fontFamilies),t._fontFamiliesLen=t.fontFamilies.length):(t._fontFamiliesPtr=U,t._fontFamiliesLen=0);if(t.locale){var F=t.locale;t._localePtr=r(F);t._localeLen=pa(F)+1}else t._localePtr=U,t._localeLen=0;if(Array.isArray(t.shadows)&&t.shadows.length){F=t.shadows;var Q=F.map(function(ba){return ba.color||
d.BLACK}),Y=F.map(function(ba){return ba.blurRadius||0});t._shadowLen=F.length;for(var ia=d._malloc(8*F.length),ka=ia/4,na=0;na<F.length;na++){var ta=F[na].offset||[0,0];d.HEAPF32[ka]=ta[0];d.HEAPF32[ka+1]=ta[1];ka+=2}t._shadowColorsPtr=D(Q).ce;t._shadowOffsetsPtr=ia;t._shadowBlurRadiiPtr=u(Y,"HEAPF32")}else t._shadowLen=0,t._shadowColorsPtr=U,t._shadowOffsetsPtr=U,t._shadowBlurRadiiPtr=U;Array.isArray(t.fontFeatures)&&t.fontFeatures.length?(F=t.fontFeatures,Q=F.map(function(ba){return ba.name}),
Y=F.map(function(ba){return ba.value}),t._fontFeatureLen=F.length,t._fontFeatureNamesPtr=l(Q),t._fontFeatureValuesPtr=u(Y,"HEAPU32")):(t._fontFeatureLen=0,t._fontFeatureNamesPtr=U,t._fontFeatureValuesPtr=U);Array.isArray(t.fontVariations)&&t.fontVariations.length?(F=t.fontVariations,Q=F.map(function(ba){return ba.axis}),Y=F.map(function(ba){return ba.value}),t._fontVariationLen=F.length,t._fontVariationAxesPtr=l(Q),t._fontVariationValuesPtr=u(Y,"HEAPF32")):(t._fontVariationLen=0,t._fontVariationAxesPtr=
U,t._fontVariationValuesPtr=U)}function B(t){d._free(t._fontFamiliesPtr);d._free(t._shadowColorsPtr);d._free(t._shadowOffsetsPtr);d._free(t._shadowBlurRadiiPtr);d._free(t._fontFeatureNamesPtr);d._free(t._fontFeatureValuesPtr)}d.Paragraph.prototype.getRectsForRange=function(t,F,Q,Y){t=this._getRectsForRange(t,F,Q,Y);return c(t)};d.Paragraph.prototype.getRectsForPlaceholders=function(){var t=this._getRectsForPlaceholders();return c(t)};d.TypefaceFontProvider.prototype.registerFont=function(t,F){t=d.Typeface.MakeFreeTypeFaceFromData(t);
if(!t)return null;F=r(F);this._registerFont(t,F)};d.ParagraphStyle=function(t){t.disableHinting=t.disableHinting||!1;if(t.ellipsis){var F=t.ellipsis;t._ellipsisPtr=r(F);t._ellipsisLen=pa(F)+1}else t._ellipsisPtr=U,t._ellipsisLen=0;null==t.heightMultiplier&&(t.heightMultiplier=-1);t.maxLines=t.maxLines||0;t.replaceTabCharacters=t.replaceTabCharacters||!1;F=(F=t.strutStyle)||{};F.strutEnabled=F.strutEnabled||!1;F.strutEnabled&&Array.isArray(F.fontFamilies)&&F.fontFamilies.length?(F._fontFamiliesPtr=
l(F.fontFamilies),F._fontFamiliesLen=F.fontFamilies.length):(F._fontFamiliesPtr=U,F._fontFamiliesLen=0);F.fontStyle=g(F.fontStyle);null==F.fontSize&&(F.fontSize=-1);null==F.heightMultiplier&&(F.heightMultiplier=-1);F.halfLeading=F.halfLeading||!1;F.leading=F.leading||0;F.forceStrutHeight=F.forceStrutHeight||!1;t.strutStyle=F;t.textAlign=t.textAlign||d.TextAlign.Start;t.textDirection=t.textDirection||d.TextDirection.LTR;t.textHeightBehavior=t.textHeightBehavior||d.TextHeightBehavior.All;t.textStyle=
d.TextStyle(t.textStyle);return t};d.TextStyle=function(t){t.color||(t.color=d.BLACK);t.decoration=t.decoration||0;t.decorationThickness=t.decorationThickness||0;t.decorationStyle=t.decorationStyle||d.DecorationStyle.Solid;t.textBaseline=t.textBaseline||d.TextBaseline.Alphabetic;null==t.fontSize&&(t.fontSize=-1);t.letterSpacing=t.letterSpacing||0;t.wordSpacing=t.wordSpacing||0;null==t.heightMultiplier&&(t.heightMultiplier=-1);t.halfLeading=t.halfLeading||!1;t.fontStyle=g(t.fontStyle);return t};var J=
{},O=d._malloc(16),L=d._malloc(16),W=d._malloc(16);d.ParagraphBuilder.Make=function(t,F){y(t.textStyle);F=d.ParagraphBuilder._Make(t,F);B(t.textStyle);return F};d.ParagraphBuilder.MakeFromFontProvider=function(t,F){y(t.textStyle);F=d.ParagraphBuilder._MakeFromFontProvider(t,F);B(t.textStyle);return F};d.ParagraphBuilder.ShapeText=function(t,F,Q){let Y=0;for(const ia of F)Y+=ia.length;if(Y!==t.length)throw"Accumulated block lengths must equal text.length";return d.ParagraphBuilder._ShapeText(t,F,Q)};
d.ParagraphBuilder.prototype.pushStyle=function(t){y(t);this._pushStyle(t);B(t)};d.ParagraphBuilder.prototype.pushPaintStyle=function(t,F,Q){y(t);this._pushPaintStyle(t,F,Q);B(t)};d.ParagraphBuilder.prototype.addPlaceholder=function(t,F,Q,Y,ia){Q=Q||d.PlaceholderAlignment.Baseline;Y=Y||d.TextBaseline.Alphabetic;this._addPlaceholder(t||0,F||0,Q,Y,ia||0)};d.ParagraphBuilder.prototype.buildWithClientInfo=function(t,F,Q,Y){var ia=u(t,"HEAPU32"),ka=u(F,"HEAPU32"),na=u(Q,"HEAPU32"),ta=u(Y,"HEAPU32"),ba=
this._buildWithClientInfo(ia,t&&t.length||0,ka,F&&F.length||0,na,Q&&Q.length||0,ta,Y&&Y.length||0);q(ia,t);q(ka,F);q(na,Q);q(ta,Y);return ba}})})(w);a.MakeManagedAnimation=function(d,c,g,l,r){if(!a._MakeManagedAnimation)throw"Not compiled with MakeManagedAnimation";g||(g="");if(!c)return a._MakeManagedAnimation(d,0,U,U,U,g,l,r);for(var y=[],B=[],J=[],O=Object.keys(c||{}),L=0;L<O.length;L++){var W=O[L],t=new Uint8Array(c[W]),F=a._malloc(t.byteLength);a.HEAPU8.set(t,F);B.push(F);J.push(t.byteLength);
t=pa(W)+1;F=a._malloc(t);qa(W,K,F,t);y.push(F)}c=u(y,"HEAPU32");B=u(B,"HEAPU32");J=u(J,"HEAPU32");d=a._MakeManagedAnimation(d,O.length,c,B,J,g,l,r);a._free(c);a._free(B);a._free(J);return d};(function(d){d.Rd=d.Rd||[];d.Rd.push(function(){d.Animation.prototype.render=function(c,g){S(g,ha);this._render(c,ha)};d.Animation.prototype.size=function(c){this._size(ha);var g=sa.toTypedArray();return c?(c[0]=g[0],c[1]=g[1],c):g.slice(0,2)};d.ManagedAnimation&&(d.ManagedAnimation.prototype.render=function(c,
g){S(g,ha);this._render(c,ha)},d.ManagedAnimation.prototype.seek=function(c,g){this._seek(c,ha);c=sa.toTypedArray();return g?(g.set(c),g):c.slice()},d.ManagedAnimation.prototype.seekFrame=function(c,g){this._seekFrame(c,ha);c=sa.toTypedArray();return g?(g.set(c),g):c.slice()},d.ManagedAnimation.prototype.setColor=function(c,g){g=z(g);return this._setColor(c,g)},d.ManagedAnimation.prototype.size=function(c){this._size(ha);var g=sa.toTypedArray();return c?(c[0]=g[0],c[1]=g[1],c):g.slice(0,2)})})})(w);
a.MakeParticles=function(d,c){if(!a._MakeParticles)throw"Not compiled with MakeParticles";if(!c)return a._MakeParticles(d,0,U,U,U);for(var g=[],l=[],r=[],y=Object.keys(c||{}),B=0;B<y.length;B++){var J=y[B],O=new Uint8Array(c[J]),L=a._malloc(O.byteLength);a.HEAPU8.set(O,L);l.push(L);r.push(O.byteLength);O=pa(J)+1;L=a._malloc(O);qa(J,K,L,O);g.push(L)}c=u(g,"HEAPU32");l=u(l,"HEAPU32");r=u(r,"HEAPU32");d=a._MakeParticles(d,y.length,c,l,r);a._free(c);a._free(l);a._free(r);return d};a.Rd=a.Rd||[];a.Rd.push(function(){a.ParticleEffect.prototype.uniforms=
function(){var d=this._uniformPtr(),c=this.getUniformFloatCount();return!d||0>=c?new Float32Array:new Float32Array(a.HEAPU8.buffer,d,c)};a.ParticleEffect.prototype.setPosition=function(d){this._setPosition(d[0],d[1])}});a.Rd=a.Rd||[];a.Rd.push(function(){a.Path.prototype.op=function(d,c){return this._op(d,c)?this:null};a.Path.prototype.simplify=function(){return this._simplify()?this:null}});a.Rd=a.Rd||[];a.Rd.push(function(){a.Canvas.prototype.drawText=function(d,c,g,l,r){var y=pa(d),B=a._malloc(y+
1);qa(d,K,B,y+1);this._drawSimpleText(B,y,c,g,r,l);a._free(B)};a.Canvas.prototype.drawGlyphs=function(d,c,g,l,r,y){if(!(2*d.length<=c.length))throw"Not enough positions for the array of gyphs";a.Od(this.Md);const B=u(d,"HEAPU16"),J=u(c,"HEAPF32");this._drawGlyphs(d.length,B,J,g,l,r,y);q(J,c);q(B,d)};a.Font.prototype.getGlyphBounds=function(d,c,g){var l=u(d,"HEAPU16"),r=a._malloc(16*d.length);this._getGlyphWidthBounds(l,d.length,U,r,c||null);c=new Float32Array(a.HEAPU8.buffer,r,4*d.length);q(l,d);
if(g)return g.set(c),a._free(r),g;d=Float32Array.from(c);a._free(r);return d};a.Font.prototype.getGlyphIDs=function(d,c,g){c||(c=d.length);var l=pa(d)+1,r=a._malloc(l);qa(d,K,r,l);d=a._malloc(2*c);c=this._getGlyphIDs(r,l-1,c,d);a._free(r);if(0>c)return a._free(d),null;r=new Uint16Array(a.HEAPU8.buffer,d,c);if(g)return g.set(r),a._free(d),g;g=Uint16Array.from(r);a._free(d);return g};a.Font.prototype.getGlyphIntercepts=function(d,c,g,l){var r=u(d,"HEAPU16"),y=u(c,"HEAPF32");return this._getGlyphIntercepts(r,
d.length,!(d&&d._ck),y,c.length,!(c&&c._ck),g,l)};a.Font.prototype.getGlyphWidths=function(d,c,g){var l=u(d,"HEAPU16"),r=a._malloc(4*d.length);this._getGlyphWidthBounds(l,d.length,r,U,c||null);c=new Float32Array(a.HEAPU8.buffer,r,d.length);q(l,d);if(g)return g.set(c),a._free(r),g;d=Float32Array.from(c);a._free(r);return d};a.FontMgr.FromData=function(){if(!arguments.length)return null;var d=arguments;1===d.length&&Array.isArray(d[0])&&(d=arguments[0]);if(!d.length)return null;for(var c=[],g=[],l=
0;l<d.length;l++){var r=new Uint8Array(d[l]),y=u(r,"HEAPU8");c.push(y);g.push(r.byteLength)}c=u(c,"HEAPU32");g=u(g,"HEAPU32");d=a.FontMgr._fromData(c,g,d.length);a._free(c);a._free(g);return d};a.Typeface.MakeFreeTypeFaceFromData=function(d){d=new Uint8Array(d);var c=u(d,"HEAPU8");return(d=a.Typeface._MakeFreeTypeFaceFromData(c,d.byteLength))?d:null};a.Typeface.prototype.getGlyphIDs=function(d,c,g){c||(c=d.length);var l=pa(d)+1,r=a._malloc(l);qa(d,K,r,l);d=a._malloc(2*c);c=this._getGlyphIDs(r,l-1,
c,d);a._free(r);if(0>c)return a._free(d),null;r=new Uint16Array(a.HEAPU8.buffer,d,c);if(g)return g.set(r),a._free(d),g;g=Uint16Array.from(r);a._free(d);return g};a.TextBlob.MakeOnPath=function(d,c,g,l){if(d&&d.length&&c&&c.countPoints()){if(1===c.countPoints())return this.MakeFromText(d,g);l||(l=0);var r=g.getGlyphIDs(d);r=g.getGlyphWidths(r);var y=[];c=new a.ContourMeasureIter(c,!1,1);for(var B=c.next(),J=new Float32Array(4),O=0;O<d.length&&B;O++){var L=r[O];l+=L/2;if(l>B.length()){B.delete();B=
c.next();if(!B){d=d.substring(0,O);break}l=L/2}B.getPosTan(l,J);var W=J[2],t=J[3];y.push(W,t,J[0]-L/2*W,J[1]-L/2*t);l+=L/2}d=this.MakeFromRSXform(d,y,g);B&&B.delete();c.delete();return d}};a.TextBlob.MakeFromRSXform=function(d,c,g){var l=pa(d)+1,r=a._malloc(l);qa(d,K,r,l);d=u(c,"HEAPF32");g=a.TextBlob._MakeFromRSXform(r,l-1,d,g);a._free(r);return g?g:null};a.TextBlob.MakeFromRSXformGlyphs=function(d,c,g){var l=u(d,"HEAPU16");c=u(c,"HEAPF32");g=a.TextBlob._MakeFromRSXformGlyphs(l,2*d.length,c,g);q(l,
d);return g?g:null};a.TextBlob.MakeFromGlyphs=function(d,c){var g=u(d,"HEAPU16");c=a.TextBlob._MakeFromGlyphs(g,2*d.length,c);q(g,d);return c?c:null};a.TextBlob.MakeFromText=function(d,c){var g=pa(d)+1,l=a._malloc(g);qa(d,K,l,g);d=a.TextBlob._MakeFromText(l,g-1,c);a._free(l);return d?d:null};a.MallocGlyphIDs=function(d){return a.Malloc(Uint16Array,d)}});a.Rd=a.Rd||[];a.Rd.push(function(){a.MakePicture=function(d){d=new Uint8Array(d);var c=a._malloc(d.byteLength);a.HEAPU8.set(d,c);return(d=a._MakePicture(c,
d.byteLength))?d:null}});a.Rd=a.Rd||[];a.Rd.push(function(){a.RuntimeEffect.Make=function(d,c){return a.RuntimeEffect._Make(d,{onError:c||function(g){console.log("RuntimeEffect error",g)}})};a.RuntimeEffect.prototype.makeShader=function(d,c){var g=!d._ck,l=u(d,"HEAPF32");c=G(c);return this._makeShader(l,4*d.length,g,c)};a.RuntimeEffect.prototype.makeShaderWithChildren=function(d,c,g){var l=!d._ck,r=u(d,"HEAPF32");g=G(g);for(var y=[],B=0;B<c.length;B++)y.push(c[B].Ld.Ud);c=u(y,"HEAPU32");return this._makeShaderWithChildren(r,
4*d.length,l,c,y.length,g)}});(function(){function d(I){for(var k=0;k<I.length;k++)if(void 0!==I[k]&&!Number.isFinite(I[k]))return!1;return!0}function c(I){var k=a.getColorComponents(I);I=k[0];var p=k[1],A=k[2];k=k[3];if(1===k)return I=I.toString(16).toLowerCase(),p=p.toString(16).toLowerCase(),A=A.toString(16).toLowerCase(),I=1===I.length?"0"+I:I,p=1===p.length?"0"+p:p,A=1===A.length?"0"+A:A,"#"+I+p+A;k=0===k||1===k?k:k.toFixed(8);return"rgba("+I+", "+p+", "+A+", "+k+")"}function g(I){return a.parseColorString(I,
na)}function l(I){I=ta.exec(I);if(!I)return null;var k=parseFloat(I[4]),p=16;switch(I[5]){case "em":case "rem":p=16*k;break;case "pt":p=4*k/3;break;case "px":p=k;break;case "pc":p=16*k;break;case "in":p=96*k;break;case "cm":p=96*k/2.54;break;case "mm":p=96/25.4*k;break;case "q":p=96/25.4/4*k;break;case "%":p=16/75*k}return{style:I[1],variant:I[2],weight:I[3],sizePx:p,family:I[6].trim()}}function r(I){this.Nd=I;this.Qd=new a.Paint;this.Qd.setAntiAlias(!0);this.Qd.setStrokeMiter(10);this.Qd.setStrokeCap(a.StrokeCap.Butt);
this.Qd.setStrokeJoin(a.StrokeJoin.Miter);this.Qe="10px monospace";this.ne=new a.Font(null,10);this.ne.setSubpixel(!0);this.be=this.he=a.BLACK;this.we=0;this.Je=a.TRANSPARENT;this.ye=this.xe=0;this.Ke=this.ke=1;this.Ie=0;this.ve=[];this.Pd=a.BlendMode.SrcOver;this.Qd.setStrokeWidth(this.Ke);this.Qd.setBlendMode(this.Pd);this.Td=new a.Path;this.Vd=a.Matrix.identity();this.mf=[];this.Ce=[];this.me=function(){this.Td.delete();this.Qd.delete();this.ne.delete();this.Ce.forEach(function(k){k.me()})};Object.defineProperty(this,
"currentTransform",{enumerable:!0,get:function(){return{a:this.Vd[0],c:this.Vd[1],e:this.Vd[2],b:this.Vd[3],d:this.Vd[4],f:this.Vd[5]}},set:function(k){k.a&&this.setTransform(k.a,k.b,k.c,k.d,k.e,k.f)}});Object.defineProperty(this,"fillStyle",{enumerable:!0,get:function(){return f(this.be)?c(this.be):this.be},set:function(k){"string"===typeof k?this.be=g(k):k.ue&&(this.be=k)}});Object.defineProperty(this,"font",{enumerable:!0,get:function(){return this.Qe},set:function(k){var p=l(k),A=p.family;p.typeface=
ba[A]?ba[A][(p.style||"normal")+"|"+(p.variant||"normal")+"|"+(p.weight||"normal")]||ba[A]["*"]:null;p&&(this.ne.setSize(p.sizePx),this.ne.setTypeface(p.typeface),this.Qe=k)}});Object.defineProperty(this,"globalAlpha",{enumerable:!0,get:function(){return this.ke},set:function(k){!isFinite(k)||0>k||1<k||(this.ke=k)}});Object.defineProperty(this,"globalCompositeOperation",{enumerable:!0,get:function(){switch(this.Pd){case a.BlendMode.SrcOver:return"source-over";case a.BlendMode.DstOver:return"destination-over";
case a.BlendMode.Src:return"copy";case a.BlendMode.Dst:return"destination";case a.BlendMode.Clear:return"clear";case a.BlendMode.SrcIn:return"source-in";case a.BlendMode.DstIn:return"destination-in";case a.BlendMode.SrcOut:return"source-out";case a.BlendMode.DstOut:return"destination-out";case a.BlendMode.SrcATop:return"source-atop";case a.BlendMode.DstATop:return"destination-atop";case a.BlendMode.Xor:return"xor";case a.BlendMode.Plus:return"lighter";case a.BlendMode.Multiply:return"multiply";case a.BlendMode.Screen:return"screen";
case a.BlendMode.Overlay:return"overlay";case a.BlendMode.Darken:return"darken";case a.BlendMode.Lighten:return"lighten";case a.BlendMode.ColorDodge:return"color-dodge";case a.BlendMode.ColorBurn:return"color-burn";case a.BlendMode.HardLight:return"hard-light";case a.BlendMode.SoftLight:return"soft-light";case a.BlendMode.Difference:return"difference";case a.BlendMode.Exclusion:return"exclusion";case a.BlendMode.Hue:return"hue";case a.BlendMode.Saturation:return"saturation";case a.BlendMode.Color:return"color";
case a.BlendMode.Luminosity:return"luminosity"}},set:function(k){switch(k){case "source-over":this.Pd=a.BlendMode.SrcOver;break;case "destination-over":this.Pd=a.BlendMode.DstOver;break;case "copy":this.Pd=a.BlendMode.Src;break;case "destination":this.Pd=a.BlendMode.Dst;break;case "clear":this.Pd=a.BlendMode.Clear;break;case "source-in":this.Pd=a.BlendMode.SrcIn;break;case "destination-in":this.Pd=a.BlendMode.DstIn;break;case "source-out":this.Pd=a.BlendMode.SrcOut;break;case "destination-out":this.Pd=
a.BlendMode.DstOut;break;case "source-atop":this.Pd=a.BlendMode.SrcATop;break;case "destination-atop":this.Pd=a.BlendMode.DstATop;break;case "xor":this.Pd=a.BlendMode.Xor;break;case "lighter":this.Pd=a.BlendMode.Plus;break;case "plus-lighter":this.Pd=a.BlendMode.Plus;break;case "plus-darker":throw"plus-darker is not supported";case "multiply":this.Pd=a.BlendMode.Multiply;break;case "screen":this.Pd=a.BlendMode.Screen;break;case "overlay":this.Pd=a.BlendMode.Overlay;break;case "darken":this.Pd=a.BlendMode.Darken;
break;case "lighten":this.Pd=a.BlendMode.Lighten;break;case "color-dodge":this.Pd=a.BlendMode.ColorDodge;break;case "color-burn":this.Pd=a.BlendMode.ColorBurn;break;case "hard-light":this.Pd=a.BlendMode.HardLight;break;case "soft-light":this.Pd=a.BlendMode.SoftLight;break;case "difference":this.Pd=a.BlendMode.Difference;break;case "exclusion":this.Pd=a.BlendMode.Exclusion;break;case "hue":this.Pd=a.BlendMode.Hue;break;case "saturation":this.Pd=a.BlendMode.Saturation;break;case "color":this.Pd=a.BlendMode.Color;
break;case "luminosity":this.Pd=a.BlendMode.Luminosity;break;default:return}this.Qd.setBlendMode(this.Pd)}});Object.defineProperty(this,"imageSmoothingEnabled",{enumerable:!0,get:function(){return!0},set:function(){}});Object.defineProperty(this,"imageSmoothingQuality",{enumerable:!0,get:function(){return"high"},set:function(){}});Object.defineProperty(this,"lineCap",{enumerable:!0,get:function(){switch(this.Qd.getStrokeCap()){case a.StrokeCap.Butt:return"butt";case a.StrokeCap.Round:return"round";
case a.StrokeCap.Square:return"square"}},set:function(k){switch(k){case "butt":this.Qd.setStrokeCap(a.StrokeCap.Butt);break;case "round":this.Qd.setStrokeCap(a.StrokeCap.Round);break;case "square":this.Qd.setStrokeCap(a.StrokeCap.Square)}}});Object.defineProperty(this,"lineDashOffset",{enumerable:!0,get:function(){return this.Ie},set:function(k){isFinite(k)&&(this.Ie=k)}});Object.defineProperty(this,"lineJoin",{enumerable:!0,get:function(){switch(this.Qd.getStrokeJoin()){case a.StrokeJoin.Miter:return"miter";
case a.StrokeJoin.Round:return"round";case a.StrokeJoin.Bevel:return"bevel"}},set:function(k){switch(k){case "miter":this.Qd.setStrokeJoin(a.StrokeJoin.Miter);break;case "round":this.Qd.setStrokeJoin(a.StrokeJoin.Round);break;case "bevel":this.Qd.setStrokeJoin(a.StrokeJoin.Bevel)}}});Object.defineProperty(this,"lineWidth",{enumerable:!0,get:function(){return this.Qd.getStrokeWidth()},set:function(k){0>=k||!k||(this.Ke=k,this.Qd.setStrokeWidth(k))}});Object.defineProperty(this,"miterLimit",{enumerable:!0,
get:function(){return this.Qd.getStrokeMiter()},set:function(k){0>=k||!k||this.Qd.setStrokeMiter(k)}});Object.defineProperty(this,"shadowBlur",{enumerable:!0,get:function(){return this.we},set:function(k){0>k||!isFinite(k)||(this.we=k)}});Object.defineProperty(this,"shadowColor",{enumerable:!0,get:function(){return c(this.Je)},set:function(k){this.Je=g(k)}});Object.defineProperty(this,"shadowOffsetX",{enumerable:!0,get:function(){return this.xe},set:function(k){isFinite(k)&&(this.xe=k)}});Object.defineProperty(this,
"shadowOffsetY",{enumerable:!0,get:function(){return this.ye},set:function(k){isFinite(k)&&(this.ye=k)}});Object.defineProperty(this,"strokeStyle",{enumerable:!0,get:function(){return c(this.he)},set:function(k){"string"===typeof k?this.he=g(k):k.ue&&(this.he=k)}});this.arc=function(k,p,A,C,E,H){F(this.Td,k,p,A,A,0,C,E,H)};this.arcTo=function(k,p,A,C,E){L(this.Td,k,p,A,C,E)};this.beginPath=function(){this.Td.delete();this.Td=new a.Path};this.bezierCurveTo=function(k,p,A,C,E,H){var N=this.Td;d([k,
p,A,C,E,H])&&(N.isEmpty()&&N.moveTo(k,p),N.cubicTo(k,p,A,C,E,H))};this.clearRect=function(k,p,A,C){this.Qd.setStyle(a.PaintStyle.Fill);this.Qd.setBlendMode(a.BlendMode.Clear);this.Nd.drawRect(a.XYWHRect(k,p,A,C),this.Qd);this.Qd.setBlendMode(this.Pd)};this.clip=function(k,p){"string"===typeof k?(p=k,k=this.Td):k&&k.Ze&&(k=k.Wd);k||(k=this.Td);k=k.copy();p&&"evenodd"===p.toLowerCase()?k.setFillType(a.FillType.EvenOdd):k.setFillType(a.FillType.Winding);this.Nd.clipPath(k,a.ClipOp.Intersect,!0);k.delete()};
this.closePath=function(){W(this.Td)};this.createImageData=function(){if(1===arguments.length){var k=arguments[0];return new J(new Uint8ClampedArray(4*k.width*k.height),k.width,k.height)}if(2===arguments.length){k=arguments[0];var p=arguments[1];return new J(new Uint8ClampedArray(4*k*p),k,p)}throw"createImageData expects 1 or 2 arguments, got "+arguments.length;};this.createLinearGradient=function(k,p,A,C){if(d(arguments)){var E=new O(k,p,A,C);this.Ce.push(E);return E}};this.createPattern=function(k,
p){k=new ia(k,p);this.Ce.push(k);return k};this.createRadialGradient=function(k,p,A,C,E,H){if(d(arguments)){var N=new ka(k,p,A,C,E,H);this.Ce.push(N);return N}};this.drawImage=function(k){k instanceof B&&(k=k.tf());var p=this.Pe();if(3===arguments.length||5===arguments.length)var A=a.XYWHRect(arguments[1],arguments[2],arguments[3]||k.width(),arguments[4]||k.height()),C=a.XYWHRect(0,0,k.width(),k.height());else if(9===arguments.length)A=a.XYWHRect(arguments[5],arguments[6],arguments[7],arguments[8]),
C=a.XYWHRect(arguments[1],arguments[2],arguments[3],arguments[4]);else throw"invalid number of args for drawImage, need 3, 5, or 9; got "+arguments.length;this.Nd.drawImageRect(k,C,A,p,!1);p.dispose()};this.ellipse=function(k,p,A,C,E,H,N,ca){F(this.Td,k,p,A,C,E,H,N,ca)};this.Pe=function(){var k=this.Qd.copy();k.setStyle(a.PaintStyle.Fill);if(f(this.be)){var p=a.multiplyByAlpha(this.be,this.ke);k.setColor(p)}else p=this.be.ue(this.Vd),k.setColor(a.Color(0,0,0,this.ke)),k.setShader(p);k.dispose=function(){this.delete()};
return k};this.fill=function(k,p){"string"===typeof k?(p=k,k=this.Td):k&&k.Ze&&(k=k.Wd);if("evenodd"===p)this.Td.setFillType(a.FillType.EvenOdd);else{if("nonzero"!==p&&p)throw"invalid fill rule";this.Td.setFillType(a.FillType.Winding)}k||(k=this.Td);p=this.Pe();var A=this.ze(p);A&&(this.Nd.save(),this.se(),this.Nd.drawPath(k,A),this.Nd.restore(),A.dispose());this.Nd.drawPath(k,p);p.dispose()};this.fillRect=function(k,p,A,C){var E=this.Pe(),H=this.ze(E);H&&(this.Nd.save(),this.se(),this.Nd.drawRect(a.XYWHRect(k,
p,A,C),H),this.Nd.restore(),H.dispose());this.Nd.drawRect(a.XYWHRect(k,p,A,C),E);E.dispose()};this.fillText=function(k,p,A){var C=this.Pe();k=a.TextBlob.MakeFromText(k,this.ne);var E=this.ze(C);E&&(this.Nd.save(),this.se(),this.Nd.drawTextBlob(k,p,A,E),this.Nd.restore(),E.dispose());this.Nd.drawTextBlob(k,p,A,C);k.delete();C.dispose()};this.getImageData=function(k,p,A,C){return(k=this.Nd.readPixels(k,p,{width:A,height:C,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB}))?
new J(new Uint8ClampedArray(k.buffer),A,C):null};this.getLineDash=function(){return this.ve.slice()};this.nf=function(k){var p=a.Matrix.invert(this.Vd);a.Matrix.mapPoints(p,k);return k};this.isPointInPath=function(k,p,A){var C=arguments;if(3===C.length)var E=this.Td;else if(4===C.length)E=C[0],k=C[1],p=C[2],A=C[3];else throw"invalid arg count, need 3 or 4, got "+C.length;if(!isFinite(k)||!isFinite(p))return!1;A=A||"nonzero";if("nonzero"!==A&&"evenodd"!==A)return!1;C=this.nf([k,p]);k=C[0];p=C[1];E.setFillType("nonzero"===
A?a.FillType.Winding:a.FillType.EvenOdd);return E.contains(k,p)};this.isPointInStroke=function(k,p){var A=arguments;if(2===A.length)var C=this.Td;else if(3===A.length)C=A[0],k=A[1],p=A[2];else throw"invalid arg count, need 2 or 3, got "+A.length;if(!isFinite(k)||!isFinite(p))return!1;A=this.nf([k,p]);k=A[0];p=A[1];C=C.copy();C.setFillType(a.FillType.Winding);C.stroke({width:this.lineWidth,miter_limit:this.miterLimit,cap:this.Qd.getStrokeCap(),join:this.Qd.getStrokeJoin(),precision:.3});A=C.contains(k,
p);C.delete();return A};this.lineTo=function(k,p){Q(this.Td,k,p)};this.measureText=function(k){k=this.ne.getGlyphIDs(k);k=this.ne.getGlyphWidths(k);let p=0;for(const A of k)p+=A;return{width:p}};this.moveTo=function(k,p){var A=this.Td;d([k,p])&&A.moveTo(k,p)};this.putImageData=function(k,p,A,C,E,H,N){if(d([p,A,C,E,H,N]))if(void 0===C)this.Nd.writePixels(k.data,k.width,k.height,p,A);else if(C=C||0,E=E||0,H=H||k.width,N=N||k.height,0>H&&(C+=H,H=Math.abs(H)),0>N&&(E+=N,N=Math.abs(N)),0>C&&(H+=C,C=0),
0>E&&(N+=E,E=0),!(0>=H||0>=N)){k=a.MakeImage({width:k.width,height:k.height,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},k.data,4*k.width);var ca=a.XYWHRect(C,E,H,N);p=a.XYWHRect(p+C,A+E,H,N);A=a.Matrix.invert(this.Vd);this.Nd.save();this.Nd.concat(A);this.Nd.drawImageRect(k,ca,p,null,!1);this.Nd.restore();k.delete()}};this.quadraticCurveTo=function(k,p,A,C){var E=this.Td;d([k,p,A,C])&&(E.isEmpty()&&E.moveTo(k,p),E.quadTo(k,p,A,C))};this.rect=function(k,
p,A,C){var E=this.Td;k=a.XYWHRect(k,p,A,C);d(k)&&E.addRect(k)};this.resetTransform=function(){this.Td.transform(this.Vd);var k=a.Matrix.invert(this.Vd);this.Nd.concat(k);this.Vd=this.Nd.getTotalMatrix()};this.restore=function(){var k=this.mf.pop();if(k){var p=a.Matrix.multiply(this.Vd,a.Matrix.invert(k.Gf));this.Td.transform(p);this.Qd.delete();this.Qd=k.$f;this.ve=k.Yf;this.Ke=k.mg;this.he=k.lg;this.be=k.fs;this.xe=k.jg;this.ye=k.kg;this.we=k.dg;this.Je=k.ig;this.ke=k.Nf;this.Pd=k.Of;this.Ie=k.Zf;
this.Qe=k.Mf;this.Nd.restore();this.Vd=this.Nd.getTotalMatrix()}};this.rotate=function(k){if(isFinite(k)){var p=a.Matrix.rotated(-k);this.Td.transform(p);this.Nd.rotate(k/Math.PI*180,0,0);this.Vd=this.Nd.getTotalMatrix()}};this.save=function(){if(this.be.te){var k=this.be.te();this.Ce.push(k)}else k=this.be;if(this.he.te){var p=this.he.te();this.Ce.push(p)}else p=this.he;this.mf.push({Gf:this.Vd.slice(),Yf:this.ve.slice(),mg:this.Ke,lg:p,fs:k,jg:this.xe,kg:this.ye,dg:this.we,ig:this.Je,Nf:this.ke,
Zf:this.Ie,Of:this.Pd,$f:this.Qd.copy(),Mf:this.Qe});this.Nd.save()};this.scale=function(k,p){if(d(arguments)){var A=a.Matrix.scaled(1/k,1/p);this.Td.transform(A);this.Nd.scale(k,p);this.Vd=this.Nd.getTotalMatrix()}};this.setLineDash=function(k){for(var p=0;p<k.length;p++)if(!isFinite(k[p])||0>k[p])return;1===k.length%2&&Array.prototype.push.apply(k,k);this.ve=k};this.setTransform=function(k,p,A,C,E,H){d(arguments)&&(this.resetTransform(),this.transform(k,p,A,C,E,H))};this.se=function(){var k=a.Matrix.invert(this.Vd);
this.Nd.concat(k);this.Nd.concat(a.Matrix.translated(this.xe,this.ye));this.Nd.concat(this.Vd)};this.ze=function(k){var p=a.multiplyByAlpha(this.Je,this.ke);if(!a.getColorComponents(p)[3]||!(this.we||this.ye||this.xe))return null;k=k.copy();k.setColor(p);var A=a.MaskFilter.MakeBlur(a.BlurStyle.Normal,this.we/2,!1);k.setMaskFilter(A);k.dispose=function(){A.delete();this.delete()};return k};this.af=function(){var k=this.Qd.copy();k.setStyle(a.PaintStyle.Stroke);if(f(this.he)){var p=a.multiplyByAlpha(this.he,
this.ke);k.setColor(p)}else p=this.he.ue(this.Vd),k.setColor(a.Color(0,0,0,this.ke)),k.setShader(p);k.setStrokeWidth(this.Ke);if(this.ve.length){var A=a.PathEffect.MakeDash(this.ve,this.Ie);k.setPathEffect(A)}k.dispose=function(){A&&A.delete();this.delete()};return k};this.stroke=function(k){k=k?k.Wd:this.Td;var p=this.af(),A=this.ze(p);A&&(this.Nd.save(),this.se(),this.Nd.drawPath(k,A),this.Nd.restore(),A.dispose());this.Nd.drawPath(k,p);p.dispose()};this.strokeRect=function(k,p,A,C){var E=this.af(),
H=this.ze(E);H&&(this.Nd.save(),this.se(),this.Nd.drawRect(a.XYWHRect(k,p,A,C),H),this.Nd.restore(),H.dispose());this.Nd.drawRect(a.XYWHRect(k,p,A,C),E);E.dispose()};this.strokeText=function(k,p,A){var C=this.af();k=a.TextBlob.MakeFromText(k,this.ne);var E=this.ze(C);E&&(this.Nd.save(),this.se(),this.Nd.drawTextBlob(k,p,A,E),this.Nd.restore(),E.dispose());this.Nd.drawTextBlob(k,p,A,C);k.delete();C.dispose()};this.translate=function(k,p){if(d(arguments)){var A=a.Matrix.translated(-k,-p);this.Td.transform(A);
this.Nd.translate(k,p);this.Vd=this.Nd.getTotalMatrix()}};this.transform=function(k,p,A,C,E,H){k=[k,A,E,p,C,H,0,0,1];p=a.Matrix.invert(k);this.Td.transform(p);this.Nd.concat(k);this.Vd=this.Nd.getTotalMatrix()};this.addHitRegion=function(){};this.clearHitRegions=function(){};this.drawFocusIfNeeded=function(){};this.removeHitRegion=function(){};this.scrollPathIntoView=function(){};Object.defineProperty(this,"canvas",{value:null,writable:!1})}function y(I){this.bf=I;this.Md=new r(I.getCanvas());this.Re=
[];this.decodeImage=function(k){k=a.MakeImageFromEncoded(k);if(!k)throw"Invalid input";this.Re.push(k);return new B(k)};this.loadFont=function(k,p){k=a.Typeface.MakeFreeTypeFaceFromData(k);if(!k)return null;this.Re.push(k);var A=(p.style||"normal")+"|"+(p.variant||"normal")+"|"+(p.weight||"normal");p=p.family;ba[p]||(ba[p]={"*":k});ba[p][A]=k};this.makePath2D=function(k){k=new Y(k);this.Re.push(k.Wd);return k};this.getContext=function(k){return"2d"===k?this.Md:null};this.toDataURL=function(k,p){this.bf.flush();
var A=this.bf.makeImageSnapshot();if(A){k=k||"image/png";var C=a.ImageFormat.PNG;"image/jpeg"===k&&(C=a.ImageFormat.JPEG);if(p=A.encodeToBytes(C,p||.92)){A.delete();k="data:"+k+";base64,";if("undefined"!==typeof Buffer)p=Buffer.from(p).toString("base64");else{A=0;C=p.length;for(var E="",H;A<C;)H=p.slice(A,Math.min(A+32768,C)),E+=String.fromCharCode.apply(null,H),A+=32768;p=btoa(E)}return k+p}}};this.dispose=function(){this.Md.me();this.Re.forEach(function(k){k.delete()});this.bf.dispose()}}function B(I){this.width=
I.width();this.height=I.height();this.naturalWidth=this.width;this.naturalHeight=this.height;this.tf=function(){return I}}function J(I,k,p){if(!k||0===p)throw"invalid dimensions, width and height must be non-zero";if(I.length%4)throw"arr must be a multiple of 4";p=p||I.length/(4*k);Object.defineProperty(this,"data",{value:I,writable:!1});Object.defineProperty(this,"height",{value:p,writable:!1});Object.defineProperty(this,"width",{value:k,writable:!1})}function O(I,k,p,A){this.Yd=null;this.de=[];
this.ae=[];this.addColorStop=function(C,E){if(0>C||1<C||!isFinite(C))throw"offset must be between 0 and 1 inclusively";E=g(E);var H=this.ae.indexOf(C);if(-1!==H)this.de[H]=E;else{for(H=0;H<this.ae.length&&!(this.ae[H]>C);H++);this.ae.splice(H,0,C);this.de.splice(H,0,E)}};this.te=function(){var C=new O(I,k,p,A);C.de=this.de.slice();C.ae=this.ae.slice();return C};this.me=function(){this.Yd&&(this.Yd.delete(),this.Yd=null)};this.ue=function(C){var E=[I,k,p,A];a.Matrix.mapPoints(C,E);C=E[0];var H=E[1],
N=E[2];E=E[3];this.me();return this.Yd=a.Shader.MakeLinearGradient([C,H],[N,E],this.de,this.ae,a.TileMode.Clamp)}}function L(I,k,p,A,C,E){if(d([k,p,A,C,E])){if(0>E)throw"radii cannot be negative";I.isEmpty()&&I.moveTo(k,p);I.arcToTangent(k,p,A,C,E)}}function W(I){if(!I.isEmpty()){var k=I.getBounds();(k[3]-k[1]||k[2]-k[0])&&I.close()}}function t(I,k,p,A,C,E,H){H=(H-E)/Math.PI*180;E=E/Math.PI*180;k=a.LTRBRect(k-A,p-C,k+A,p+C);1E-5>Math.abs(Math.abs(H)-360)?(p=H/2,I.arcToOval(k,E,p,!1),I.arcToOval(k,
E+p,p,!1)):I.arcToOval(k,E,H,!1)}function F(I,k,p,A,C,E,H,N,ca){if(d([k,p,A,C,E,H,N])){if(0>A||0>C)throw"radii cannot be negative";var da=2*Math.PI,Ia=H%da;0>Ia&&(Ia+=da);var bb=Ia-H;H=Ia;N+=bb;!ca&&N-H>=da?N=H+da:ca&&H-N>=da?N=H-da:!ca&&H>N?N=H+(da-(H-N)%da):ca&&H<N&&(N=H-(da-(N-H)%da));E?(ca=a.Matrix.rotated(E,k,p),E=a.Matrix.rotated(-E,k,p),I.transform(E),t(I,k,p,A,C,H,N),I.transform(ca)):t(I,k,p,A,C,H,N)}}function Q(I,k,p){d([k,p])&&(I.isEmpty()&&I.moveTo(k,p),I.lineTo(k,p))}function Y(I){this.Wd=
null;this.Wd="string"===typeof I?a.Path.MakeFromSVGString(I):I&&I.Ze?I.Wd.copy():new a.Path;this.Ze=function(){return this.Wd};this.addPath=function(k,p){p||(p={a:1,c:0,e:0,b:0,d:1,f:0});this.Wd.addPath(k.Wd,[p.a,p.c,p.e,p.b,p.d,p.f])};this.arc=function(k,p,A,C,E,H){F(this.Wd,k,p,A,A,0,C,E,H)};this.arcTo=function(k,p,A,C,E){L(this.Wd,k,p,A,C,E)};this.bezierCurveTo=function(k,p,A,C,E,H){var N=this.Wd;d([k,p,A,C,E,H])&&(N.isEmpty()&&N.moveTo(k,p),N.cubicTo(k,p,A,C,E,H))};this.closePath=function(){W(this.Wd)};
this.ellipse=function(k,p,A,C,E,H,N,ca){F(this.Wd,k,p,A,C,E,H,N,ca)};this.lineTo=function(k,p){Q(this.Wd,k,p)};this.moveTo=function(k,p){var A=this.Wd;d([k,p])&&A.moveTo(k,p)};this.quadraticCurveTo=function(k,p,A,C){var E=this.Wd;d([k,p,A,C])&&(E.isEmpty()&&E.moveTo(k,p),E.quadTo(k,p,A,C))};this.rect=function(k,p,A,C){var E=this.Wd;k=a.XYWHRect(k,p,A,C);d(k)&&E.addRect(k)}}function ia(I,k){this.Yd=null;I instanceof B&&(I=I.tf());this.Bf=I;this._transform=a.Matrix.identity();""===k&&(k="repeat");switch(k){case "repeat-x":this.Ae=
a.TileMode.Repeat;this.Be=a.TileMode.Decal;break;case "repeat-y":this.Ae=a.TileMode.Decal;this.Be=a.TileMode.Repeat;break;case "repeat":this.Be=this.Ae=a.TileMode.Repeat;break;case "no-repeat":this.Be=this.Ae=a.TileMode.Decal;break;default:throw"invalid repetition mode "+k;}this.setTransform=function(p){p=[p.a,p.c,p.e,p.b,p.d,p.f,0,0,1];d(p)&&(this._transform=p)};this.te=function(){var p=new ia;p.Ae=this.Ae;p.Be=this.Be;return p};this.me=function(){this.Yd&&(this.Yd.delete(),this.Yd=null)};this.ue=
function(){this.me();return this.Yd=this.Bf.makeShaderCubic(this.Ae,this.Be,1/3,1/3,this._transform)}}function ka(I,k,p,A,C,E){this.Yd=null;this.de=[];this.ae=[];this.addColorStop=function(H,N){if(0>H||1<H||!isFinite(H))throw"offset must be between 0 and 1 inclusively";N=g(N);var ca=this.ae.indexOf(H);if(-1!==ca)this.de[ca]=N;else{for(ca=0;ca<this.ae.length&&!(this.ae[ca]>H);ca++);this.ae.splice(ca,0,H);this.de.splice(ca,0,N)}};this.te=function(){var H=new ka(I,k,p,A,C,E);H.de=this.de.slice();H.ae=
this.ae.slice();return H};this.me=function(){this.Yd&&(this.Yd.delete(),this.Yd=null)};this.ue=function(H){var N=[I,k,A,C];a.Matrix.mapPoints(H,N);var ca=N[0],da=N[1],Ia=N[2];N=N[3];var bb=(Math.abs(H[0])+Math.abs(H[4]))/2;H=p*bb;bb*=E;this.me();return this.Yd=a.Shader.MakeTwoPointConicalGradient([ca,da],H,[Ia,N],bb,this.de,this.ae,a.TileMode.Clamp)}}a._testing={};var na={aliceblue:Float32Array.of(.941,.973,1,1),antiquewhite:Float32Array.of(.98,.922,.843,1),aqua:Float32Array.of(0,1,1,1),aquamarine:Float32Array.of(.498,
1,.831,1),azure:Float32Array.of(.941,1,1,1),beige:Float32Array.of(.961,.961,.863,1),bisque:Float32Array.of(1,.894,.769,1),black:Float32Array.of(0,0,0,1),blanchedalmond:Float32Array.of(1,.922,.804,1),blue:Float32Array.of(0,0,1,1),blueviolet:Float32Array.of(.541,.169,.886,1),brown:Float32Array.of(.647,.165,.165,1),burlywood:Float32Array.of(.871,.722,.529,1),cadetblue:Float32Array.of(.373,.62,.627,1),chartreuse:Float32Array.of(.498,1,0,1),chocolate:Float32Array.of(.824,.412,.118,1),coral:Float32Array.of(1,
.498,.314,1),cornflowerblue:Float32Array.of(.392,.584,.929,1),cornsilk:Float32Array.of(1,.973,.863,1),crimson:Float32Array.of(.863,.078,.235,1),cyan:Float32Array.of(0,1,1,1),darkblue:Float32Array.of(0,0,.545,1),darkcyan:Float32Array.of(0,.545,.545,1),darkgoldenrod:Float32Array.of(.722,.525,.043,1),darkgray:Float32Array.of(.663,.663,.663,1),darkgreen:Float32Array.of(0,.392,0,1),darkgrey:Float32Array.of(.663,.663,.663,1),darkkhaki:Float32Array.of(.741,.718,.42,1),darkmagenta:Float32Array.of(.545,0,
.545,1),darkolivegreen:Float32Array.of(.333,.42,.184,1),darkorange:Float32Array.of(1,.549,0,1),darkorchid:Float32Array.of(.6,.196,.8,1),darkred:Float32Array.of(.545,0,0,1),darksalmon:Float32Array.of(.914,.588,.478,1),darkseagreen:Float32Array.of(.561,.737,.561,1),darkslateblue:Float32Array.of(.282,.239,.545,1),darkslategray:Float32Array.of(.184,.31,.31,1),darkslategrey:Float32Array.of(.184,.31,.31,1),darkturquoise:Float32Array.of(0,.808,.82,1),darkviolet:Float32Array.of(.58,0,.827,1),deeppink:Float32Array.of(1,
.078,.576,1),deepskyblue:Float32Array.of(0,.749,1,1),dimgray:Float32Array.of(.412,.412,.412,1),dimgrey:Float32Array.of(.412,.412,.412,1),dodgerblue:Float32Array.of(.118,.565,1,1),firebrick:Float32Array.of(.698,.133,.133,1),floralwhite:Float32Array.of(1,.98,.941,1),forestgreen:Float32Array.of(.133,.545,.133,1),fuchsia:Float32Array.of(1,0,1,1),gainsboro:Float32Array.of(.863,.863,.863,1),ghostwhite:Float32Array.of(.973,.973,1,1),gold:Float32Array.of(1,.843,0,1),goldenrod:Float32Array.of(.855,.647,.125,
1),gray:Float32Array.of(.502,.502,.502,1),green:Float32Array.of(0,.502,0,1),greenyellow:Float32Array.of(.678,1,.184,1),grey:Float32Array.of(.502,.502,.502,1),honeydew:Float32Array.of(.941,1,.941,1),hotpink:Float32Array.of(1,.412,.706,1),indianred:Float32Array.of(.804,.361,.361,1),indigo:Float32Array.of(.294,0,.51,1),ivory:Float32Array.of(1,1,.941,1),khaki:Float32Array.of(.941,.902,.549,1),lavender:Float32Array.of(.902,.902,.98,1),lavenderblush:Float32Array.of(1,.941,.961,1),lawngreen:Float32Array.of(.486,
.988,0,1),lemonchiffon:Float32Array.of(1,.98,.804,1),lightblue:Float32Array.of(.678,.847,.902,1),lightcoral:Float32Array.of(.941,.502,.502,1),lightcyan:Float32Array.of(.878,1,1,1),lightgoldenrodyellow:Float32Array.of(.98,.98,.824,1),lightgray:Float32Array.of(.827,.827,.827,1),lightgreen:Float32Array.of(.565,.933,.565,1),lightgrey:Float32Array.of(.827,.827,.827,1),lightpink:Float32Array.of(1,.714,.757,1),lightsalmon:Float32Array.of(1,.627,.478,1),lightseagreen:Float32Array.of(.125,.698,.667,1),lightskyblue:Float32Array.of(.529,
.808,.98,1),lightslategray:Float32Array.of(.467,.533,.6,1),lightslategrey:Float32Array.of(.467,.533,.6,1),lightsteelblue:Float32Array.of(.69,.769,.871,1),lightyellow:Float32Array.of(1,1,.878,1),lime:Float32Array.of(0,1,0,1),limegreen:Float32Array.of(.196,.804,.196,1),linen:Float32Array.of(.98,.941,.902,1),magenta:Float32Array.of(1,0,1,1),maroon:Float32Array.of(.502,0,0,1),mediumaquamarine:Float32Array.of(.4,.804,.667,1),mediumblue:Float32Array.of(0,0,.804,1),mediumorchid:Float32Array.of(.729,.333,
.827,1),mediumpurple:Float32Array.of(.576,.439,.859,1),mediumseagreen:Float32Array.of(.235,.702,.443,1),mediumslateblue:Float32Array.of(.482,.408,.933,1),mediumspringgreen:Float32Array.of(0,.98,.604,1),mediumturquoise:Float32Array.of(.282,.82,.8,1),mediumvioletred:Float32Array.of(.78,.082,.522,1),midnightblue:Float32Array.of(.098,.098,.439,1),mintcream:Float32Array.of(.961,1,.98,1),mistyrose:Float32Array.of(1,.894,.882,1),moccasin:Float32Array.of(1,.894,.71,1),navajowhite:Float32Array.of(1,.871,.678,
1),navy:Float32Array.of(0,0,.502,1),oldlace:Float32Array.of(.992,.961,.902,1),olive:Float32Array.of(.502,.502,0,1),olivedrab:Float32Array.of(.42,.557,.137,1),orange:Float32Array.of(1,.647,0,1),orangered:Float32Array.of(1,.271,0,1),orchid:Float32Array.of(.855,.439,.839,1),palegoldenrod:Float32Array.of(.933,.91,.667,1),palegreen:Float32Array.of(.596,.984,.596,1),paleturquoise:Float32Array.of(.686,.933,.933,1),palevioletred:Float32Array.of(.859,.439,.576,1),papayawhip:Float32Array.of(1,.937,.835,1),
peachpuff:Float32Array.of(1,.855,.725,1),peru:Float32Array.of(.804,.522,.247,1),pink:Float32Array.of(1,.753,.796,1),plum:Float32Array.of(.867,.627,.867,1),powderblue:Float32Array.of(.69,.878,.902,1),purple:Float32Array.of(.502,0,.502,1),rebeccapurple:Float32Array.of(.4,.2,.6,1),red:Float32Array.of(1,0,0,1),rosybrown:Float32Array.of(.737,.561,.561,1),royalblue:Float32Array.of(.255,.412,.882,1),saddlebrown:Float32Array.of(.545,.271,.075,1),salmon:Float32Array.of(.98,.502,.447,1),sandybrown:Float32Array.of(.957,
.643,.376,1),seagreen:Float32Array.of(.18,.545,.341,1),seashell:Float32Array.of(1,.961,.933,1),sienna:Float32Array.of(.627,.322,.176,1),silver:Float32Array.of(.753,.753,.753,1),skyblue:Float32Array.of(.529,.808,.922,1),slateblue:Float32Array.of(.416,.353,.804,1),slategray:Float32Array.of(.439,.502,.565,1),slategrey:Float32Array.of(.439,.502,.565,1),snow:Float32Array.of(1,.98,.98,1),springgreen:Float32Array.of(0,1,.498,1),steelblue:Float32Array.of(.275,.51,.706,1),tan:Float32Array.of(.824,.706,.549,
1),teal:Float32Array.of(0,.502,.502,1),thistle:Float32Array.of(.847,.749,.847,1),tomato:Float32Array.of(1,.388,.278,1),transparent:Float32Array.of(0,0,0,0),turquoise:Float32Array.of(.251,.878,.816,1),violet:Float32Array.of(.933,.51,.933,1),wheat:Float32Array.of(.961,.871,.702,1),white:Float32Array.of(1,1,1,1),whitesmoke:Float32Array.of(.961,.961,.961,1),yellow:Float32Array.of(1,1,0,1),yellowgreen:Float32Array.of(.604,.804,.196,1)};a._testing.parseColor=g;a._testing.colorToString=c;var ta=RegExp("(italic|oblique|normal|)\\s*(small-caps|normal|)\\s*(bold|bolder|lighter|[1-9]00|normal|)\\s*([\\d\\.]+)(px|pt|pc|in|cm|mm|%|em|ex|ch|rem|q)(.+)"),
ba={"Noto Mono":{"*":null},monospace:{"*":null}};a._testing.parseFontString=l;a.MakeCanvas=function(I,k){return(I=a.MakeSurface(I,k))?new y(I):null};a.ImageData=function(){if(2===arguments.length){var I=arguments[0],k=arguments[1];return new J(new Uint8ClampedArray(4*I*k),I,k)}if(3===arguments.length){var p=arguments[0];if(p.prototype.constructor!==Uint8ClampedArray)throw"bytes must be given as a Uint8ClampedArray";I=arguments[1];k=arguments[2];if(p%4)throw"bytes must be given in a multiple of 4";
if(p%I)throw"bytes must divide evenly by width";if(k&&k!==p/(4*I))throw"invalid height given";return new J(p,I,p/(4*I))}throw"invalid number of arguments - takes 2 or 3, saw "+arguments.length;}})()})(w);var ua=Object.assign({},w),va="./this.program",wa=(a,b)=>{throw b;},xa="object"==typeof window,za="function"==typeof importScripts,Aa="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,Ba="",Ca,Da,Ea,fs,Fa,Ga;
if(Aa)Ba=za?require("path").dirname(Ba)+"/":__dirname+"/",Ga=()=>{Fa||(fs=require("fs"),Fa=require("path"))},Ca=function(a,b){Ga();a=Fa.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")},Ea=a=>{a=Ca(a,!0);a.buffer||(a=new Uint8Array(a));return a},Da=(a,b,e)=>{Ga();a=Fa.normalize(a);fs.readFile(a,function(f,h){f?e(f):b(h.buffer)})},1<process.argv.length&&(va=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),process.on("unhandledRejection",function(a){throw a;}),wa=(a,b)=>{if(noExitRuntime)throw process.exitCode=
a,b;b instanceof Ha||Ja("exiting due to exception: "+b);process.exit(a)},w.inspect=function(){return"[Emscripten Module object]"};else if(xa||za)za?Ba=self.location.href:"undefined"!=typeof document&&document.currentScript&&(Ba=document.currentScript.src),_scriptDir&&(Ba=_scriptDir),0!==Ba.indexOf("blob:")?Ba=Ba.substr(0,Ba.replace(/[?#].*/,"").lastIndexOf("/")+1):Ba="",Ca=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},za&&(Ea=a=>{var b=new XMLHttpRequest;b.open("GET",
a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),Da=(a,b,e)=>{var f=new XMLHttpRequest;f.open("GET",a,!0);f.responseType="arraybuffer";f.onload=()=>{200==f.status||0==f.status&&f.response?b(f.response):e()};f.onerror=e;f.send(null)};var Ka=w.print||console.log.bind(console),Ja=w.printErr||console.warn.bind(console);Object.assign(w,ua);ua=null;w.thisProgram&&(va=w.thisProgram);w.quit&&(wa=w.quit);var La=0,Ma;w.wasmBinary&&(Ma=w.wasmBinary);
var noExitRuntime=w.noExitRuntime||!0;"object"!=typeof WebAssembly&&Na("no native wasm support detected");var Qa,Ra=!1,Sa="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function Ta(a,b,e){var f=b+e;for(e=b;a[e]&&!(e>=f);)++e;if(16<e-b&&a.buffer&&Sa)return Sa.decode(a.subarray(b,e));for(f="";b<e;){var h=a[b++];if(h&128){var m=a[b++]&63;if(192==(h&224))f+=String.fromCharCode((h&31)<<6|m);else{var v=a[b++]&63;h=224==(h&240)?(h&15)<<12|m<<6|v:(h&7)<<18|m<<12|v<<6|a[b++]&63;65536>h?f+=String.fromCharCode(h):(h-=65536,f+=String.fromCharCode(55296|h>>10,56320|h&1023))}}else f+=String.fromCharCode(h)}return f}function Va(a,b){return a?Ta(K,a,b):""}
function qa(a,b,e,f){if(!(0<f))return 0;var h=e;f=e+f-1;for(var m=0;m<a.length;++m){var v=a.charCodeAt(m);if(55296<=v&&57343>=v){var n=a.charCodeAt(++m);v=65536+((v&1023)<<10)|n&1023}if(127>=v){if(e>=f)break;b[e++]=v}else{if(2047>=v){if(e+1>=f)break;b[e++]=192|v>>6}else{if(65535>=v){if(e+2>=f)break;b[e++]=224|v>>12}else{if(e+3>=f)break;b[e++]=240|v>>18;b[e++]=128|v>>12&63}b[e++]=128|v>>6&63}b[e++]=128|v&63}}b[e]=0;return e-h}
function pa(a){for(var b=0,e=0;e<a.length;++e){var f=a.charCodeAt(e);55296<=f&&57343>=f&&(f=65536+((f&1023)<<10)|a.charCodeAt(++e)&1023);127>=f?++b:b=2047>=f?b+2:65535>=f?b+3:b+4}return b}var Wa="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0;function Xa(a,b){var e=a>>1;for(var f=e+b/2;!(e>=f)&&Ya[e];)++e;e<<=1;if(32<e-a&&Wa)return Wa.decode(K.subarray(a,e));e="";for(f=0;!(f>=b/2);++f){var h=Za[a+2*f>>1];if(0==h)break;e+=String.fromCharCode(h)}return e}
function $a(a,b,e){void 0===e&&(e=2147483647);if(2>e)return 0;e-=2;var f=b;e=e<2*a.length?e/2:a.length;for(var h=0;h<e;++h)Za[b>>1]=a.charCodeAt(h),b+=2;Za[b>>1]=0;return b-f}function cb(a){return 2*a.length}function db(a,b){for(var e=0,f="";!(e>=b/4);){var h=R[a+4*e>>2];if(0==h)break;++e;65536<=h?(h-=65536,f+=String.fromCharCode(55296|h>>10,56320|h&1023)):f+=String.fromCharCode(h)}return f}
function eb(a,b,e){void 0===e&&(e=2147483647);if(4>e)return 0;var f=b;e=f+e-4;for(var h=0;h<a.length;++h){var m=a.charCodeAt(h);if(55296<=m&&57343>=m){var v=a.charCodeAt(++h);m=65536+((m&1023)<<10)|v&1023}R[b>>2]=m;b+=4;if(b+4>e)break}R[b>>2]=0;return b-f}function fb(a){for(var b=0,e=0;e<a.length;++e){var f=a.charCodeAt(e);55296<=f&&57343>=f&&++e;b+=4}return b}var jb,kb,K,Za,Ya,R,lb,T,mb;
function nb(){var a=Qa.buffer;jb=a;w.HEAP8=kb=new Int8Array(a);w.HEAP16=Za=new Int16Array(a);w.HEAP32=R=new Int32Array(a);w.HEAPU8=K=new Uint8Array(a);w.HEAPU16=Ya=new Uint16Array(a);w.HEAPU32=lb=new Uint32Array(a);w.HEAPF32=T=new Float32Array(a);w.HEAPF64=mb=new Float64Array(a)}var ob,pb=[],qb=[],rb=[];function sb(){var a=w.preRun.shift();pb.unshift(a)}var tb=0,ub=null,vb=null;
function Na(a){if(w.onAbort)w.onAbort(a);a="Aborted("+a+")";Ja(a);Ra=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ea(a);throw a;}function wb(){return yb.startsWith("data:application/octet-stream;base64,")}var yb;yb="canvaskit.wasm";if(!wb()){var zb=yb;yb=w.locateFile?w.locateFile(zb,Ba):Ba+zb}function Ab(){var a=yb;try{if(a==yb&&Ma)return new Uint8Array(Ma);if(Ea)return Ea(a);throw"both async and sync fetching of the wasm failed";}catch(b){Na(b)}}
function Bb(){if(!Ma&&(xa||za)){if("function"==typeof fetch&&!yb.startsWith("file://"))return fetch(yb,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+yb+"'";return a.arrayBuffer()}).catch(function(){return Ab()});if(Da)return new Promise(function(a,b){Da(yb,function(e){a(new Uint8Array(e))},b)})}return Promise.resolve().then(function(){return Ab()})}function Cb(a){for(;0<a.length;)a.shift()(w)}function Db(a){return ob.get(a)}var Eb={};
function Fb(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function Gb(a){return this.fromWireType(R[a>>2])}var Hb={},Ib={},Jb={};function Kb(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}function Lb(a,b){a=Kb(a);return function(){null;return b.apply(this,arguments)}}
function Mb(a){var b=Error,e=Lb(a,function(f){this.name=a;this.message=f;f=Error(f).stack;void 0!==f&&(this.stack=this.toString()+"\n"+f.replace(/^Error(:[^\n]*)?\n/,""))});e.prototype=Object.create(b.prototype);e.prototype.constructor=e;e.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return e}var Nb=void 0;function Ob(a){throw new Nb(a);}
function Pb(a,b,e){function f(n){n=e(n);n.length!==a.length&&Ob("Mismatched type converter count");for(var q=0;q<a.length;++q)Qb(a[q],n[q])}a.forEach(function(n){Jb[n]=b});var h=Array(b.length),m=[],v=0;b.forEach((n,q)=>{Ib.hasOwnProperty(n)?h[q]=Ib[n]:(m.push(n),Hb.hasOwnProperty(n)||(Hb[n]=[]),Hb[n].push(()=>{h[q]=Ib[n];++v;v===m.length&&f(h)}))});0===m.length&&f(h)}
function Rb(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var Sb=void 0;function Tb(a){for(var b="";K[a];)b+=Sb[K[a++]];return b}var ac=void 0;function X(a){throw new ac(a);}
function Qb(a,b,e={}){if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var f=b.name;a||X('type "'+f+'" must have a positive integer typeid pointer');if(Ib.hasOwnProperty(a)){if(e.Vf)return;X("Cannot register type '"+f+"' twice")}Ib[a]=b;delete Jb[a];Hb.hasOwnProperty(a)&&(b=Hb[a],delete Hb[a],b.forEach(h=>h()))}function bc(a){X(a.Ld.Xd.Sd.name+" instance already deleted")}var cc=!1;function dc(){}
function ec(a){--a.count.value;0===a.count.value&&(a.$d?a.fe.le(a.$d):a.Xd.Sd.le(a.Ud))}function fc(a,b,e){if(b===e)return a;if(void 0===e.ie)return null;a=fc(a,b,e.ie);return null===a?null:e.Jf(a)}var gc={},hc=[];function ic(){for(;hc.length;){var a=hc.pop();a.Ld.Fe=!1;a["delete"]()}}var jc=void 0,kc={};function lc(a,b){for(void 0===b&&X("ptr should not be undefined");a.ie;)b=a.Oe(b),a=a.ie;return kc[b]}
function mc(a,b){b.Xd&&b.Ud||Ob("makeClassHandle requires ptr and ptrType");!!b.fe!==!!b.$d&&Ob("Both smartPtrType and smartPtr must be specified");b.count={value:1};return nc(Object.create(a,{Ld:{value:b}}))}function nc(a){if("undefined"===typeof FinalizationRegistry)return nc=b=>b,a;cc=new FinalizationRegistry(b=>{ec(b.Ld)});nc=b=>{var e=b.Ld;e.$d&&cc.register(b,{Ld:e},b);return b};dc=b=>{cc.unregister(b)};return nc(a)}function oc(){}
function pc(a,b,e){if(void 0===a[b].Zd){var f=a[b];a[b]=function(){a[b].Zd.hasOwnProperty(arguments.length)||X("Function '"+e+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].Zd+")!");return a[b].Zd[arguments.length].apply(this,arguments)};a[b].Zd=[];a[b].Zd[f.De]=f}}
function qc(a,b,e){w.hasOwnProperty(a)?((void 0===e||void 0!==w[a].Zd&&void 0!==w[a].Zd[e])&&X("Cannot register public name '"+a+"' twice"),pc(w,a,a),w.hasOwnProperty(e)&&X("Cannot register multiple overloads of a function with the same number of arguments ("+e+")!"),w[a].Zd[e]=b):(w[a]=b,void 0!==e&&(w[a].tg=e))}function rc(a,b,e,f,h,m,v,n){this.name=a;this.constructor=b;this.Ge=e;this.le=f;this.ie=h;this.Pf=m;this.Oe=v;this.Jf=n;this.bg=[]}
function sc(a,b,e){for(;b!==e;)b.Oe||X("Expected null or instance of "+e.name+", got an instance of "+b.name),a=b.Oe(a),b=b.ie;return a}function tc(a,b){if(null===b)return this.ff&&X("null is not a valid "+this.name),0;b.Ld||X('Cannot pass "'+uc(b)+'" as a '+this.name);b.Ld.Ud||X("Cannot pass deleted object as a pointer of type "+this.name);return sc(b.Ld.Ud,b.Ld.Xd.Sd,this.Sd)}
function vc(a,b){if(null===b){this.ff&&X("null is not a valid "+this.name);if(this.Te){var e=this.gf();null!==a&&a.push(this.le,e);return e}return 0}b.Ld||X('Cannot pass "'+uc(b)+'" as a '+this.name);b.Ld.Ud||X("Cannot pass deleted object as a pointer of type "+this.name);!this.Se&&b.Ld.Xd.Se&&X("Cannot convert argument of type "+(b.Ld.fe?b.Ld.fe.name:b.Ld.Xd.name)+" to parameter type "+this.name);e=sc(b.Ld.Ud,b.Ld.Xd.Sd,this.Sd);if(this.Te)switch(void 0===b.Ld.$d&&X("Passing raw pointer to smart pointer is illegal"),
this.hg){case 0:b.Ld.fe===this?e=b.Ld.$d:X("Cannot convert argument of type "+(b.Ld.fe?b.Ld.fe.name:b.Ld.Xd.name)+" to parameter type "+this.name);break;case 1:e=b.Ld.$d;break;case 2:if(b.Ld.fe===this)e=b.Ld.$d;else{var f=b.clone();e=this.cg(e,wc(function(){f["delete"]()}));null!==a&&a.push(this.le,e)}break;default:X("Unsupporting sharing policy")}return e}
function xc(a,b){if(null===b)return this.ff&&X("null is not a valid "+this.name),0;b.Ld||X('Cannot pass "'+uc(b)+'" as a '+this.name);b.Ld.Ud||X("Cannot pass deleted object as a pointer of type "+this.name);b.Ld.Xd.Se&&X("Cannot convert argument of type "+b.Ld.Xd.name+" to parameter type "+this.name);return sc(b.Ld.Ud,b.Ld.Xd.Sd,this.Sd)}
function yc(a,b,e,f,h,m,v,n,q,u,D){this.name=a;this.Sd=b;this.ff=e;this.Se=f;this.Te=h;this.ag=m;this.hg=v;this.vf=n;this.gf=q;this.cg=u;this.le=D;h||void 0!==b.ie?this.toWireType=vc:(this.toWireType=f?tc:xc,this.ee=null)}function zc(a,b,e){w.hasOwnProperty(a)||Ob("Replacing nonexistant public symbol");void 0!==w[a].Zd&&void 0!==e?w[a].Zd[e]=b:(w[a]=b,w[a].De=e)}
function Ac(a,b){var e=[];return function(){e.length=0;Object.assign(e,arguments);if(a.includes("j")){var f=w["dynCall_"+a];f=e&&e.length?f.apply(null,[b].concat(e)):f.call(null,b)}else f=Db(b).apply(null,e);return f}}function Bc(a,b){a=Tb(a);var e=a.includes("j")?Ac(a,b):Db(b);"function"!=typeof e&&X("unknown function pointer with signature "+a+": "+b);return e}var Ic=void 0;function Jc(a){a=Kc(a);var b=Tb(a);Lc(a);return b}
function Mc(a,b){function e(m){h[m]||Ib[m]||(Jb[m]?Jb[m].forEach(e):(f.push(m),h[m]=!0))}var f=[],h={};b.forEach(e);throw new Ic(a+": "+f.map(Jc).join([", "]));}
function Nc(a,b,e,f,h){var m=b.length;2>m&&X("argTypes array size mismatch! Must at least get return value and 'this' types!");var v=null!==b[1]&&null!==e,n=!1;for(e=1;e<b.length;++e)if(null!==b[e]&&void 0===b[e].ee){n=!0;break}var q="void"!==b[0].name,u=m-2,D=Array(u),G=[],M=[];return function(){arguments.length!==u&&X("function "+a+" called with "+arguments.length+" arguments, expected "+u+" args!");M.length=0;G.length=v?2:1;G[0]=h;if(v){var z=b[1].toWireType(M,this);G[1]=z}for(var P=0;P<u;++P)D[P]=
b[P+2].toWireType(M,arguments[P]),G.push(D[P]);P=f.apply(null,G);if(n)Fb(M);else for(var V=v?1:2;V<b.length;V++){var S=1===V?z:D[V-2];null!==b[V].ee&&b[V].ee(S)}z=q?b[0].fromWireType(P):void 0;return z}}function Oc(a,b){for(var e=[],f=0;f<a;f++)e.push(lb[b+4*f>>2]);return e}var Pc=[],Qc=[{},{value:void 0},{value:null},{value:!0},{value:!1}];function Rc(a){4<a&&0===--Qc[a].hf&&(Qc[a]=void 0,Pc.push(a))}
var Sc=a=>{a||X("Cannot use deleted val. handle = "+a);return Qc[a].value},wc=a=>{switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=Pc.length?Pc.pop():Qc.length;Qc[b]={hf:1,value:a};return b}};
function Tc(a,b,e){switch(b){case 0:return function(f){return this.fromWireType((e?kb:K)[f])};case 1:return function(f){return this.fromWireType((e?Za:Ya)[f>>1])};case 2:return function(f){return this.fromWireType((e?R:lb)[f>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function Uc(a,b){var e=Ib[a];void 0===e&&X(b+" has unknown type "+Jc(a));return e}function uc(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function Vc(a,b){switch(b){case 2:return function(e){return this.fromWireType(T[e>>2])};case 3:return function(e){return this.fromWireType(mb[e>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function Wc(a,b,e){switch(b){case 0:return e?function(f){return kb[f]}:function(f){return K[f]};case 1:return e?function(f){return Za[f>>1]}:function(f){return Ya[f>>1]};case 2:return e?function(f){return R[f>>2]}:function(f){return lb[f>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var Xc={};function Yc(a){var b=Xc[a];return void 0===b?Tb(a):b}var Zc=[];
function $c(){function a(b){b.$$$embind_global$$$=b;var e="object"==typeof $$$embind_global$$$&&b.$$$embind_global$$$==b;e||delete b.$$$embind_global$$$;return e}if("object"==typeof globalThis)return globalThis;if("object"==typeof $$$embind_global$$$)return $$$embind_global$$$;"object"==typeof global&&a(global)?$$$embind_global$$$=global:"object"==typeof self&&a(self)&&($$$embind_global$$$=self);if("object"==typeof $$$embind_global$$$)return $$$embind_global$$$;throw Error("unable to get global object.");
}function ad(a){var b=Zc.length;Zc.push(a);return b}function bd(a,b){for(var e=Array(a),f=0;f<a;++f)e[f]=Uc(lb[b+4*f>>2],"parameter "+f);return e}var cd=[];function dd(a){var b=Array(a+1);return function(e,f,h){b[0]=e;for(var m=0;m<a;++m){var v=Uc(lb[f+4*m>>2],"parameter "+m);b[m+1]=v.readValueFromPointer(h);h+=v.argPackAdvance}e=new (e.bind.apply(e,b));return wc(e)}}var ed={},fd;fd=Aa?()=>{var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:()=>performance.now();
function gd(a){var b=a.getExtension("ANGLE_instanced_arrays");b&&(a.vertexAttribDivisor=function(e,f){b.vertexAttribDivisorANGLE(e,f)},a.drawArraysInstanced=function(e,f,h,m){b.drawArraysInstancedANGLE(e,f,h,m)},a.drawElementsInstanced=function(e,f,h,m,v){b.drawElementsInstancedANGLE(e,f,h,m,v)})}
function hd(a){var b=a.getExtension("OES_vertex_array_object");b&&(a.createVertexArray=function(){return b.createVertexArrayOES()},a.deleteVertexArray=function(e){b.deleteVertexArrayOES(e)},a.bindVertexArray=function(e){b.bindVertexArrayOES(e)},a.isVertexArray=function(e){return b.isVertexArrayOES(e)})}function jd(a){var b=a.getExtension("WEBGL_draw_buffers");b&&(a.drawBuffers=function(e,f){b.drawBuffersWEBGL(e,f)})}
var kd=1,ld=[],md=[],nd=[],od=[],ja=[],pd=[],qd=[],oa=[],rd=[],sd=[],td={},ud={},vd=4;function wd(a){xd||(xd=a)}function fa(a){for(var b=kd++,e=a.length;e<b;e++)a[e]=null;return b}function la(a,b){a.lf||(a.lf=a.getContext,a.getContext=function(f,h){h=a.lf(f,h);return"webgl"==f==h instanceof WebGLRenderingContext?h:null});var e=1<b.majorVersion?a.getContext("webgl2",b):a.getContext("webgl",b);return e?Ad(e,b):0}
function Ad(a,b){var e=fa(oa),f={Uf:e,attributes:b,version:b.majorVersion,ge:a};a.canvas&&(a.canvas.yf=f);oa[e]=f;("undefined"==typeof b.Kf||b.Kf)&&Bd(f);return e}function ma(a){x=oa[a];w.rg=Z=x&&x.ge;return!(a&&!Z)}
function Bd(a){a||(a=x);if(!a.Wf){a.Wf=!0;var b=a.ge;gd(b);hd(b);jd(b);b.qf=b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");b.uf=b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");2<=a.version&&(b.rf=b.getExtension("EXT_disjoint_timer_query_webgl2"));if(2>a.version||!b.rf)b.rf=b.getExtension("EXT_disjoint_timer_query");b.sg=b.getExtension("WEBGL_multi_draw");(b.getSupportedExtensions()||[]).forEach(function(e){e.includes("lose_context")||e.includes("debug")||b.getExtension(e)})}}
var x,xd,Cd=[];function Dd(a,b,e,f){for(var h=0;h<a;h++){var m=Z[e](),v=m&&fa(f);m?(m.name=v,f[v]=m):wd(1282);R[b+4*h>>2]=v}}
function Ed(a,b,e){if(b){var f=void 0;switch(a){case 36346:f=1;break;case 36344:0!=e&&1!=e&&wd(1280);return;case 34814:case 36345:f=0;break;case 34466:var h=Z.getParameter(34467);f=h?h.length:0;break;case 33309:if(2>x.version){wd(1282);return}f=2*(Z.getSupportedExtensions()||[]).length;break;case 33307:case 33308:if(2>x.version){wd(1280);return}f=33307==a?3:0}if(void 0===f)switch(h=Z.getParameter(a),typeof h){case "number":f=h;break;case "boolean":f=h?1:0;break;case "string":wd(1280);return;case "object":if(null===
h)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34229:case 36662:case 36663:case 35053:case 35055:case 36010:case 35097:case 35869:case 32874:case 36389:case 35983:case 35368:case 34068:f=0;break;default:wd(1280);return}else{if(h instanceof Float32Array||h instanceof Uint32Array||h instanceof Int32Array||h instanceof Array){for(a=0;a<h.length;++a)switch(e){case 0:R[b+4*a>>2]=h[a];break;case 2:T[b+4*a>>2]=h[a];break;case 4:kb[b+a>>0]=h[a]?1:0}return}try{f=h.name|
0}catch(m){wd(1280);Ja("GL_INVALID_ENUM in glGet"+e+"v: Unknown object returned from WebGL getParameter("+a+")! (error: "+m+")");return}}break;default:wd(1280);Ja("GL_INVALID_ENUM in glGet"+e+"v: Native code calling glGet"+e+"v("+a+") and it returns "+h+" of type "+typeof h+"!");return}switch(e){case 1:e=f;lb[b>>2]=e;lb[b+4>>2]=(e-lb[b>>2])/4294967296;break;case 0:R[b>>2]=f;break;case 2:T[b>>2]=f;break;case 4:kb[b>>0]=f?1:0}}else wd(1281)}
function Fd(a){var b=pa(a)+1,e=Gd(b);qa(a,K,e,b);return e}function Hd(a){return"]"==a.slice(-1)&&a.lastIndexOf("[")}function Id(a){a-=5120;return 0==a?kb:1==a?K:2==a?Za:4==a?R:6==a?T:5==a||28922==a||28520==a||30779==a||30782==a?lb:Ya}function Jd(a,b,e,f,h){a=Id(a);var m=31-Math.clz32(a.BYTES_PER_ELEMENT),v=vd;return a.subarray(h>>m,h+f*(e*({5:3,6:4,8:2,29502:3,29504:4,26917:2,26918:2,29846:3,29847:4}[b-6402]||1)*(1<<m)+v-1&-v)>>m)}
function Kd(a){var b=Z.Hf;if(b){var e=b.Ne[a];"number"==typeof e&&(b.Ne[a]=e=Z.getUniformLocation(b,b.wf[a]+(0<e?"["+e+"]":"")));return e}wd(1282)}var Ld=[],Md=[],Nd={};
function Od(){if(!Pd){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:va||"./this.program"},b;for(b in Nd)void 0===Nd[b]?delete a[b]:a[b]=Nd[b];var e=[];for(b in a)e.push(b+"="+a[b]);Pd=e}return Pd}var Pd,Qd=[null,[],[]];function Rd(a){return 0===a%4&&(0!==a%100||0===a%400)}
var Sd=[31,29,31,30,31,30,31,31,30,31,30,31],Td=[31,28,31,30,31,30,31,31,30,31,30,31];
function Ud(a,b,e,f){function h(z,P,V){for(z="number"==typeof z?z.toString():z||"";z.length<P;)z=V[0]+z;return z}function m(z,P){return h(z,P,"0")}function v(z,P){function V(ra){return 0>ra?-1:0<ra?1:0}var S;0===(S=V(z.getFullYear()-P.getFullYear()))&&0===(S=V(z.getMonth()-P.getMonth()))&&(S=V(z.getDate()-P.getDate()));return S}function n(z){switch(z.getDay()){case 0:return new Date(z.getFullYear()-1,11,29);case 1:return z;case 2:return new Date(z.getFullYear(),0,3);case 3:return new Date(z.getFullYear(),
0,2);case 4:return new Date(z.getFullYear(),0,1);case 5:return new Date(z.getFullYear()-1,11,31);case 6:return new Date(z.getFullYear()-1,11,30)}}function q(z){var P=z.pe;for(z=new Date((new Date(z.qe+1900,0,1)).getTime());0<P;){var V=z.getMonth(),S=(Rd(z.getFullYear())?Sd:Td)[V];if(P>S-z.getDate())P-=S-z.getDate()+1,z.setDate(1),11>V?z.setMonth(V+1):(z.setMonth(0),z.setFullYear(z.getFullYear()+1));else{z.setDate(z.getDate()+P);break}}V=new Date(z.getFullYear()+1,0,4);P=n(new Date(z.getFullYear(),
0,4));V=n(V);return 0>=v(P,z)?0>=v(V,z)?z.getFullYear()+1:z.getFullYear():z.getFullYear()-1}var u=R[f+40>>2];f={pg:R[f>>2],og:R[f+4>>2],Xe:R[f+8>>2],jf:R[f+12>>2],Ye:R[f+16>>2],qe:R[f+20>>2],je:R[f+24>>2],pe:R[f+28>>2],vg:R[f+32>>2],ng:R[f+36>>2],qg:u?Va(u):""};e=Va(e);u={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y",
"%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var D in u)e=e.replace(new RegExp(D,"g"),u[D]);var G="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),M="January February March April May June July August September October November December".split(" ");u={"%a":function(z){return G[z.je].substring(0,3)},"%A":function(z){return G[z.je]},"%b":function(z){return M[z.Ye].substring(0,3)},
"%B":function(z){return M[z.Ye]},"%C":function(z){return m((z.qe+1900)/100|0,2)},"%d":function(z){return m(z.jf,2)},"%e":function(z){return h(z.jf,2," ")},"%g":function(z){return q(z).toString().substring(2)},"%G":function(z){return q(z)},"%H":function(z){return m(z.Xe,2)},"%I":function(z){z=z.Xe;0==z?z=12:12<z&&(z-=12);return m(z,2)},"%j":function(z){for(var P=0,V=0;V<=z.Ye-1;P+=(Rd(z.qe+1900)?Sd:Td)[V++]);return m(z.jf+P,3)},"%m":function(z){return m(z.Ye+1,2)},"%M":function(z){return m(z.og,2)},
"%n":function(){return"\n"},"%p":function(z){return 0<=z.Xe&&12>z.Xe?"AM":"PM"},"%S":function(z){return m(z.pg,2)},"%t":function(){return"\t"},"%u":function(z){return z.je||7},"%U":function(z){return m(Math.floor((z.pe+7-z.je)/7),2)},"%V":function(z){var P=Math.floor((z.pe+7-(z.je+6)%7)/7);2>=(z.je+371-z.pe-2)%7&&P++;if(P)53==P&&(V=(z.je+371-z.pe)%7,4==V||3==V&&Rd(z.qe)||(P=1));else{P=52;var V=(z.je+7-z.pe-1)%7;(4==V||5==V&&Rd(z.qe%400-1))&&P++}return m(P,2)},"%w":function(z){return z.je},"%W":function(z){return m(Math.floor((z.pe+
7-(z.je+6)%7)/7),2)},"%y":function(z){return(z.qe+1900).toString().substring(2)},"%Y":function(z){return z.qe+1900},"%z":function(z){z=z.ng;var P=0<=z;z=Math.abs(z)/60;return(P?"+":"-")+String("0000"+(z/60*100+z%60)).slice(-4)},"%Z":function(z){return z.qg},"%%":function(){return"%"}};e=e.replace(/%%/g,"\x00\x00");for(D in u)e.includes(D)&&(e=e.replace(new RegExp(D,"g"),u[D](f)));e=e.replace(/\0\0/g,"%");D=Vd(e);if(D.length>b)return 0;kb.set(D,a);return D.length-1}Nb=w.InternalError=Mb("InternalError");
for(var Wd=Array(256),Xd=0;256>Xd;++Xd)Wd[Xd]=String.fromCharCode(Xd);Sb=Wd;ac=w.BindingError=Mb("BindingError");oc.prototype.isAliasOf=function(a){if(!(this instanceof oc&&a instanceof oc))return!1;var b=this.Ld.Xd.Sd,e=this.Ld.Ud,f=a.Ld.Xd.Sd;for(a=a.Ld.Ud;b.ie;)e=b.Oe(e),b=b.ie;for(;f.ie;)a=f.Oe(a),f=f.ie;return b===f&&e===a};
oc.prototype.clone=function(){this.Ld.Ud||bc(this);if(this.Ld.Me)return this.Ld.count.value+=1,this;var a=nc,b=Object,e=b.create,f=Object.getPrototypeOf(this),h=this.Ld;a=a(e.call(b,f,{Ld:{value:{count:h.count,Fe:h.Fe,Me:h.Me,Ud:h.Ud,Xd:h.Xd,$d:h.$d,fe:h.fe}}}));a.Ld.count.value+=1;a.Ld.Fe=!1;return a};oc.prototype["delete"]=function(){this.Ld.Ud||bc(this);this.Ld.Fe&&!this.Ld.Me&&X("Object already scheduled for deletion");dc(this);ec(this.Ld);this.Ld.Me||(this.Ld.$d=void 0,this.Ld.Ud=void 0)};
oc.prototype.isDeleted=function(){return!this.Ld.Ud};oc.prototype.deleteLater=function(){this.Ld.Ud||bc(this);this.Ld.Fe&&!this.Ld.Me&&X("Object already scheduled for deletion");hc.push(this);1===hc.length&&jc&&jc(ic);this.Ld.Fe=!0;return this};w.getInheritedInstanceCount=function(){return Object.keys(kc).length};w.getLiveInheritedInstances=function(){var a=[],b;for(b in kc)kc.hasOwnProperty(b)&&a.push(kc[b]);return a};w.flushPendingDeletes=ic;w.setDelayFunction=function(a){jc=a;hc.length&&jc&&jc(ic)};
yc.prototype.Qf=function(a){this.vf&&(a=this.vf(a));return a};yc.prototype.pf=function(a){this.le&&this.le(a)};yc.prototype.argPackAdvance=8;yc.prototype.readValueFromPointer=Gb;yc.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
yc.prototype.fromWireType=function(a){function b(){return this.Te?mc(this.Sd.Ge,{Xd:this.ag,Ud:e,fe:this,$d:a}):mc(this.Sd.Ge,{Xd:this,Ud:a})}var e=this.Qf(a);if(!e)return this.pf(a),null;var f=lc(this.Sd,e);if(void 0!==f){if(0===f.Ld.count.value)return f.Ld.Ud=e,f.Ld.$d=a,f.clone();f=f.clone();this.pf(a);return f}f=this.Sd.Pf(e);f=gc[f];if(!f)return b.call(this);f=this.Se?f.Ff:f.pointerType;var h=fc(e,this.Sd,f.Sd);return null===h?b.call(this):this.Te?mc(f.Sd.Ge,{Xd:f,Ud:h,fe:this,$d:a}):mc(f.Sd.Ge,
{Xd:f,Ud:h})};Ic=w.UnboundTypeError=Mb("UnboundTypeError");w.count_emval_handles=function(){for(var a=0,b=5;b<Qc.length;++b)void 0!==Qc[b]&&++a;return a};w.get_first_emval=function(){for(var a=5;a<Qc.length;++a)if(void 0!==Qc[a])return Qc[a];return null};for(var Z,Yd=0;32>Yd;++Yd)Cd.push(Array(Yd));var Zd=new Float32Array(288);for(Yd=0;288>Yd;++Yd)Ld[Yd]=Zd.subarray(0,Yd+1);var $d=new Int32Array(288);for(Yd=0;288>Yd;++Yd)Md[Yd]=$d.subarray(0,Yd+1);
function Vd(a){var b=Array(pa(a)+1);qa(a,b,0,b.length);return b}
var oe={W:function(){return 0},Bb:function(){},Db:function(){return 0},yb:function(){},zb:function(){},Y:function(){},Ab:function(){},D:function(a){var b=Eb[a];delete Eb[a];var e=b.gf,f=b.le,h=b.sf,m=h.map(v=>v.Tf).concat(h.map(v=>v.fg));Pb([a],m,v=>{var n={};h.forEach((q,u)=>{var D=v[u],G=q.Rf,M=q.Sf,z=v[u+h.length],P=q.eg,V=q.gg;n[q.Lf]={read:S=>D.fromWireType(G(M,S)),write:(S,ra)=>{var ya=[];P(V,S,z.toWireType(ya,ra));Fb(ya)}}});return[{name:b.name,fromWireType:function(q){var u={},D;for(D in n)u[D]=
n[D].read(q);f(q);return u},toWireType:function(q,u){for(var D in n)if(!(D in u))throw new TypeError('Missing field:  "'+D+'"');var G=e();for(D in n)n[D].write(G,u[D]);null!==q&&q.push(f,G);return G},argPackAdvance:8,readValueFromPointer:Gb,ee:f}]})},qb:function(){},Hb:function(a,b,e,f,h){var m=Rb(e);b=Tb(b);Qb(a,{name:b,fromWireType:function(v){return!!v},toWireType:function(v,n){return n?f:h},argPackAdvance:8,readValueFromPointer:function(v){if(1===e)var n=kb;else if(2===e)n=Za;else if(4===e)n=
R;else throw new TypeError("Unknown boolean type size: "+b);return this.fromWireType(n[v>>m])},ee:null})},p:function(a,b,e,f,h,m,v,n,q,u,D,G,M){D=Tb(D);m=Bc(h,m);n&&(n=Bc(v,n));u&&(u=Bc(q,u));M=Bc(G,M);var z=Kb(D);qc(z,function(){Mc("Cannot construct "+D+" due to unbound types",[f])});Pb([a,b,e],f?[f]:[],function(P){P=P[0];if(f){var V=P.Sd;var S=V.Ge}else S=oc.prototype;P=Lb(z,function(){if(Object.getPrototypeOf(this)!==ra)throw new ac("Use 'new' to construct "+D);if(void 0===ya.oe)throw new ac(D+
" has no accessible constructor");var hb=ya.oe[arguments.length];if(void 0===hb)throw new ac("Tried to invoke ctor of "+D+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(ya.oe).toString()+") parameters instead!");return hb.apply(this,arguments)});var ra=Object.create(S,{constructor:{value:P}});P.prototype=ra;var ya=new rc(D,P,ra,M,V,m,n,u);V=new yc(D,ya,!0,!1,!1);S=new yc(D+"*",ya,!1,!1,!1);var gb=new yc(D+" const*",ya,!1,!0,!1);gc[a]={pointerType:S,Ff:gb};zc(z,
P);return[V,S,gb]})},i:function(a,b,e,f,h,m,v){var n=Oc(e,f);b=Tb(b);m=Bc(h,m);Pb([],[a],function(q){function u(){Mc("Cannot call "+D+" due to unbound types",n)}q=q[0];var D=q.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);var G=q.Sd.constructor;void 0===G[b]?(u.De=e-1,G[b]=u):(pc(G,b,D),G[b].Zd[e-1]=u);Pb([],n,function(M){M=[M[0],null].concat(M.slice(1));M=Nc(D,M,null,m,v);void 0===G[b].Zd?(M.De=e-1,G[b]=M):G[b].Zd[e-1]=M;return[]});return[]})},F:function(a,b,e,f,h,m){0<b||Na();var v=
Oc(b,e);h=Bc(f,h);Pb([],[a],function(n){n=n[0];var q="constructor "+n.name;void 0===n.Sd.oe&&(n.Sd.oe=[]);if(void 0!==n.Sd.oe[b-1])throw new ac("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+n.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");n.Sd.oe[b-1]=()=>{Mc("Cannot construct "+n.name+" due to unbound types",v)};Pb([],v,function(u){u.splice(1,0,null);n.Sd.oe[b-1]=Nc(q,u,null,h,m);return[]});
return[]})},b:function(a,b,e,f,h,m,v,n){var q=Oc(e,f);b=Tb(b);m=Bc(h,m);Pb([],[a],function(u){function D(){Mc("Cannot call "+G+" due to unbound types",q)}u=u[0];var G=u.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);n&&u.Sd.bg.push(b);var M=u.Sd.Ge,z=M[b];void 0===z||void 0===z.Zd&&z.className!==u.name&&z.De===e-2?(D.De=e-2,D.className=u.name,M[b]=D):(pc(M,b,G),M[b].Zd[e-2]=D);Pb([],q,function(P){P=Nc(G,P,u,m,v);void 0===M[b].Zd?(P.De=e-2,M[b]=P):M[b].Zd[e-2]=P;return[]});return[]})},t:function(a,
b,e){a=Tb(a);Pb([],[b],function(f){f=f[0];w[a]=f.fromWireType(e);return[]})},Gb:function(a,b){b=Tb(b);Qb(a,{name:b,fromWireType:function(e){var f=Sc(e);Rc(e);return f},toWireType:function(e,f){return wc(f)},argPackAdvance:8,readValueFromPointer:Gb,ee:null})},n:function(a,b,e,f){function h(){}e=Rb(e);b=Tb(b);h.values={};Qb(a,{name:b,constructor:h,fromWireType:function(m){return this.constructor.values[m]},toWireType:function(m,v){return v.value},argPackAdvance:8,readValueFromPointer:Tc(b,e,f),ee:null});
qc(b,h)},f:function(a,b,e){var f=Uc(a,"enum");b=Tb(b);a=f.constructor;f=Object.create(f.constructor.prototype,{value:{value:e},constructor:{value:Lb(f.name+"_"+b,function(){})}});a.values[e]=f;a[b]=f},_:function(a,b,e){e=Rb(e);b=Tb(b);Qb(a,{name:b,fromWireType:function(f){return f},toWireType:function(f,h){return h},argPackAdvance:8,readValueFromPointer:Vc(b,e),ee:null})},x:function(a,b,e,f,h,m){var v=Oc(b,e);a=Tb(a);h=Bc(f,h);qc(a,function(){Mc("Cannot call "+a+" due to unbound types",v)},b-1);Pb([],
v,function(n){n=[n[0],null].concat(n.slice(1));zc(a,Nc(a,n,null,h,m),b-1);return[]})},H:function(a,b,e,f,h){b=Tb(b);-1===h&&(h=4294967295);h=Rb(e);var m=n=>n;if(0===f){var v=32-8*e;m=n=>n<<v>>>v}e=b.includes("unsigned")?function(n,q){return q>>>0}:function(n,q){return q};Qb(a,{name:b,fromWireType:m,toWireType:e,argPackAdvance:8,readValueFromPointer:Wc(b,h,0!==f),ee:null})},y:function(a,b,e){function f(m){m>>=2;var v=lb;return new h(jb,v[m+1],v[m])}var h=[Int8Array,Uint8Array,Int16Array,Uint16Array,
Int32Array,Uint32Array,Float32Array,Float64Array][b];e=Tb(e);Qb(a,{name:e,fromWireType:f,argPackAdvance:8,readValueFromPointer:f},{Vf:!0})},s:function(a,b,e,f,h,m,v,n,q,u,D,G){e=Tb(e);m=Bc(h,m);n=Bc(v,n);u=Bc(q,u);G=Bc(D,G);Pb([a],[b],function(M){M=M[0];return[new yc(e,M.Sd,!1,!1,!0,M,f,m,n,u,G)]})},Z:function(a,b){b=Tb(b);var e="std::string"===b;Qb(a,{name:b,fromWireType:function(f){var h=lb[f>>2],m=f+4;if(e)for(var v=m,n=0;n<=h;++n){var q=m+n;if(n==h||0==K[q]){v=Va(v,q-v);if(void 0===u)var u=v;
else u+=String.fromCharCode(0),u+=v;v=q+1}}else{u=Array(h);for(n=0;n<h;++n)u[n]=String.fromCharCode(K[m+n]);u=u.join("")}Lc(f);return u},toWireType:function(f,h){h instanceof ArrayBuffer&&(h=new Uint8Array(h));var m="string"==typeof h;m||h instanceof Uint8Array||h instanceof Uint8ClampedArray||h instanceof Int8Array||X("Cannot pass non-string to std::string");var v=e&&m?pa(h):h.length;var n=Gd(4+v+1),q=n+4;lb[n>>2]=v;if(e&&m)qa(h,K,q,v+1);else if(m)for(m=0;m<v;++m){var u=h.charCodeAt(m);255<u&&(Lc(q),
X("String has UTF-16 code units that do not fit in 8 bits"));K[q+m]=u}else for(m=0;m<v;++m)K[q+m]=h[m];null!==f&&f.push(Lc,n);return n},argPackAdvance:8,readValueFromPointer:Gb,ee:function(f){Lc(f)}})},Q:function(a,b,e){e=Tb(e);if(2===b){var f=Xa;var h=$a;var m=cb;var v=()=>Ya;var n=1}else 4===b&&(f=db,h=eb,m=fb,v=()=>lb,n=2);Qb(a,{name:e,fromWireType:function(q){for(var u=lb[q>>2],D=v(),G,M=q+4,z=0;z<=u;++z){var P=q+4+z*b;if(z==u||0==D[P>>n])M=f(M,P-M),void 0===G?G=M:(G+=String.fromCharCode(0),G+=
M),M=P+b}Lc(q);return G},toWireType:function(q,u){"string"!=typeof u&&X("Cannot pass non-string to C++ string type "+e);var D=m(u),G=Gd(4+D+b);lb[G>>2]=D>>n;h(u,G+4,D+b);null!==q&&q.push(Lc,G);return G},argPackAdvance:8,readValueFromPointer:Gb,ee:function(q){Lc(q)}})},E:function(a,b,e,f,h,m){Eb[a]={name:Tb(b),gf:Bc(e,f),le:Bc(h,m),sf:[]}},g:function(a,b,e,f,h,m,v,n,q,u){Eb[a].sf.push({Lf:Tb(b),Tf:e,Rf:Bc(f,h),Sf:m,fg:v,eg:Bc(n,q),gg:u})},Ib:function(a,b){b=Tb(b);Qb(a,{Xf:!0,name:b,argPackAdvance:0,
fromWireType:function(){},toWireType:function(){}})},Fb:function(){return!0},sb:function(){throw Infinity;},G:function(a,b,e){a=Sc(a);b=Uc(b,"emval::as");var f=[],h=wc(f);lb[e>>2]=h;return b.toWireType(f,a)},M:function(a,b,e,f,h){a=Zc[a];b=Sc(b);e=Yc(e);var m=[];lb[f>>2]=wc(m);return a(b,e,m,h)},A:function(a,b,e,f){a=Zc[a];b=Sc(b);e=Yc(e);a(b,e,null,f)},e:Rc,L:function(a){if(0===a)return wc($c());a=Yc(a);return wc($c()[a])},v:function(a,b){var e=bd(a,b),f=e[0];b=f.name+"_$"+e.slice(1).map(function(v){return v.name}).join("_")+
"$";var h=cd[b];if(void 0!==h)return h;var m=Array(a-1);h=ad((v,n,q,u)=>{for(var D=0,G=0;G<a-1;++G)m[G]=e[G+1].readValueFromPointer(u+D),D+=e[G+1].argPackAdvance;v=v[n].apply(v,m);for(G=0;G<a-1;++G)e[G+1].If&&e[G+1].If(m[G]);if(!f.Xf)return f.toWireType(q,v)});return cd[b]=h},I:function(a,b){a=Sc(a);b=Sc(b);return wc(a[b])},r:function(a){4<a&&(Qc[a].hf+=1)},N:function(a,b,e,f){a=Sc(a);var h=ed[b];h||(h=dd(b),ed[b]=h);return h(a,e,f)},J:function(){return wc([])},h:function(a){return wc(Yc(a))},C:function(){return wc({})},
mb:function(a){a=Sc(a);return!a},B:function(a){var b=Sc(a);Fb(b);Rc(a)},k:function(a,b,e){a=Sc(a);b=Sc(b);e=Sc(e);a[b]=e},j:function(a,b){a=Uc(a,"_emval_take_value");a=a.readValueFromPointer(b);return wc(a)},ub:function(){return-52},vb:function(){},a:function(){Na("")},Eb:fd,ad:function(a){Z.activeTexture(a)},bd:function(a,b){Z.attachShader(md[a],pd[b])},ca:function(a,b,e){Z.bindAttribLocation(md[a],b,Va(e))},da:function(a,b){35051==a?Z.cf=b:35052==a&&(Z.Ee=b);Z.bindBuffer(a,ld[b])},ba:function(a,
b){Z.bindFramebuffer(a,nd[b])},ec:function(a,b){Z.bindRenderbuffer(a,od[b])},Qb:function(a,b){Z.bindSampler(a,rd[b])},ea:function(a,b){Z.bindTexture(a,ja[b])},Bc:function(a){Z.bindVertexArray(qd[a])},wc:function(a){Z.bindVertexArray(qd[a])},fa:function(a,b,e,f){Z.blendColor(a,b,e,f)},ga:function(a){Z.blendEquation(a)},ha:function(a,b){Z.blendFunc(a,b)},_b:function(a,b,e,f,h,m,v,n,q,u){Z.blitFramebuffer(a,b,e,f,h,m,v,n,q,u)},ia:function(a,b,e,f){2<=x.version?e&&b?Z.bufferData(a,K,f,e,b):Z.bufferData(a,
b,f):Z.bufferData(a,e?K.subarray(e,e+b):b,f)},ja:function(a,b,e,f){2<=x.version?e&&Z.bufferSubData(a,b,K,f,e):Z.bufferSubData(a,b,K.subarray(f,f+e))},fc:function(a){return Z.checkFramebufferStatus(a)},U:function(a){Z.clear(a)},aa:function(a,b,e,f){Z.clearColor(a,b,e,f)},X:function(a){Z.clearStencil(a)},kb:function(a,b,e,f){return Z.clientWaitSync(sd[a],b,(e>>>0)+4294967296*f)},ka:function(a,b,e,f){Z.colorMask(!!a,!!b,!!e,!!f)},la:function(a){Z.compileShader(pd[a])},ma:function(a,b,e,f,h,m,v,n){2<=
x.version?Z.Ee||!v?Z.compressedTexImage2D(a,b,e,f,h,m,v,n):Z.compressedTexImage2D(a,b,e,f,h,m,K,n,v):Z.compressedTexImage2D(a,b,e,f,h,m,n?K.subarray(n,n+v):null)},na:function(a,b,e,f,h,m,v,n,q){2<=x.version?Z.Ee||!n?Z.compressedTexSubImage2D(a,b,e,f,h,m,v,n,q):Z.compressedTexSubImage2D(a,b,e,f,h,m,v,K,q,n):Z.compressedTexSubImage2D(a,b,e,f,h,m,v,q?K.subarray(q,q+n):null)},Yb:function(a,b,e,f,h){Z.copyBufferSubData(a,b,e,f,h)},oa:function(a,b,e,f,h,m,v,n){Z.copyTexSubImage2D(a,b,e,f,h,m,v,n)},pa:function(){var a=
fa(md),b=Z.createProgram();b.name=a;b.We=b.Ue=b.Ve=0;b.kf=1;md[a]=b;return a},qa:function(a){var b=fa(pd);pd[b]=Z.createShader(a);return b},ra:function(a){Z.cullFace(a)},sa:function(a,b){for(var e=0;e<a;e++){var f=R[b+4*e>>2],h=ld[f];h&&(Z.deleteBuffer(h),h.name=0,ld[f]=null,f==Z.cf&&(Z.cf=0),f==Z.Ee&&(Z.Ee=0))}},gc:function(a,b){for(var e=0;e<a;++e){var f=R[b+4*e>>2],h=nd[f];h&&(Z.deleteFramebuffer(h),h.name=0,nd[f]=null)}},ta:function(a){if(a){var b=md[a];b?(Z.deleteProgram(b),b.name=0,md[a]=null):
wd(1281)}},hc:function(a,b){for(var e=0;e<a;e++){var f=R[b+4*e>>2],h=od[f];h&&(Z.deleteRenderbuffer(h),h.name=0,od[f]=null)}},Rb:function(a,b){for(var e=0;e<a;e++){var f=R[b+4*e>>2],h=rd[f];h&&(Z.deleteSampler(h),h.name=0,rd[f]=null)}},ua:function(a){if(a){var b=pd[a];b?(Z.deleteShader(b),pd[a]=null):wd(1281)}},Zb:function(a){if(a){var b=sd[a];b?(Z.deleteSync(b),b.name=0,sd[a]=null):wd(1281)}},va:function(a,b){for(var e=0;e<a;e++){var f=R[b+4*e>>2],h=ja[f];h&&(Z.deleteTexture(h),h.name=0,ja[f]=null)}},
Cc:function(a,b){for(var e=0;e<a;e++){var f=R[b+4*e>>2];Z.deleteVertexArray(qd[f]);qd[f]=null}},xc:function(a,b){for(var e=0;e<a;e++){var f=R[b+4*e>>2];Z.deleteVertexArray(qd[f]);qd[f]=null}},wa:function(a){Z.depthMask(!!a)},xa:function(a){Z.disable(a)},ya:function(a){Z.disableVertexAttribArray(a)},za:function(a,b,e){Z.drawArrays(a,b,e)},zc:function(a,b,e,f){Z.drawArraysInstanced(a,b,e,f)},uc:function(a,b,e,f,h){Z.qf.drawArraysInstancedBaseInstanceWEBGL(a,b,e,f,h)},sc:function(a,b){for(var e=Cd[a],
f=0;f<a;f++)e[f]=R[b+4*f>>2];Z.drawBuffers(e)},Aa:function(a,b,e,f){Z.drawElements(a,b,e,f)},Ac:function(a,b,e,f,h){Z.drawElementsInstanced(a,b,e,f,h)},vc:function(a,b,e,f,h,m,v){Z.qf.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a,b,e,f,h,m,v)},mc:function(a,b,e,f,h,m){Z.drawElements(a,f,h,m)},Ba:function(a){Z.enable(a)},Ca:function(a){Z.enableVertexAttribArray(a)},Wb:function(a,b){return(a=Z.fenceSync(a,b))?(b=fa(sd),a.name=b,sd[b]=a,b):0},Da:function(){Z.finish()},Ea:function(){Z.flush()},ic:function(a,
b,e,f){Z.framebufferRenderbuffer(a,b,e,od[f])},jc:function(a,b,e,f,h){Z.framebufferTexture2D(a,b,e,ja[f],h)},Fa:function(a){Z.frontFace(a)},Ga:function(a,b){Dd(a,b,"createBuffer",ld)},kc:function(a,b){Dd(a,b,"createFramebuffer",nd)},lc:function(a,b){Dd(a,b,"createRenderbuffer",od)},Sb:function(a,b){Dd(a,b,"createSampler",rd)},Ha:function(a,b){Dd(a,b,"createTexture",ja)},Dc:function(a,b){Dd(a,b,"createVertexArray",qd)},yc:function(a,b){Dd(a,b,"createVertexArray",qd)},ac:function(a){Z.generateMipmap(a)},
Ia:function(a,b,e){e?R[e>>2]=Z.getBufferParameter(a,b):wd(1281)},Ja:function(){var a=Z.getError()||xd;xd=0;return a},Ka:function(a,b){Ed(a,b,2)},bc:function(a,b,e,f){a=Z.getFramebufferAttachmentParameter(a,b,e);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;R[f>>2]=a},O:function(a,b){Ed(a,b,0)},La:function(a,b,e,f){a=Z.getProgramInfoLog(md[a]);null===a&&(a="(unknown error)");b=0<b&&f?qa(a,K,f,b):0;e&&(R[e>>2]=b)},Ma:function(a,b,e){if(e)if(a>=kd)wd(1281);else if(a=md[a],35716==
b)a=Z.getProgramInfoLog(a),null===a&&(a="(unknown error)"),R[e>>2]=a.length+1;else if(35719==b){if(!a.We)for(b=0;b<Z.getProgramParameter(a,35718);++b)a.We=Math.max(a.We,Z.getActiveUniform(a,b).name.length+1);R[e>>2]=a.We}else if(35722==b){if(!a.Ue)for(b=0;b<Z.getProgramParameter(a,35721);++b)a.Ue=Math.max(a.Ue,Z.getActiveAttrib(a,b).name.length+1);R[e>>2]=a.Ue}else if(35381==b){if(!a.Ve)for(b=0;b<Z.getProgramParameter(a,35382);++b)a.Ve=Math.max(a.Ve,Z.getActiveUniformBlockName(a,b).length+1);R[e>>
2]=a.Ve}else R[e>>2]=Z.getProgramParameter(a,b);else wd(1281)},cc:function(a,b,e){e?R[e>>2]=Z.getRenderbufferParameter(a,b):wd(1281)},Na:function(a,b,e,f){a=Z.getShaderInfoLog(pd[a]);null===a&&(a="(unknown error)");b=0<b&&f?qa(a,K,f,b):0;e&&(R[e>>2]=b)},Nb:function(a,b,e,f){a=Z.getShaderPrecisionFormat(a,b);R[e>>2]=a.rangeMin;R[e+4>>2]=a.rangeMax;R[f>>2]=a.precision},Oa:function(a,b,e){e?35716==b?(a=Z.getShaderInfoLog(pd[a]),null===a&&(a="(unknown error)"),R[e>>2]=a?a.length+1:0):35720==b?(a=Z.getShaderSource(pd[a]),
R[e>>2]=a?a.length+1:0):R[e>>2]=Z.getShaderParameter(pd[a],b):wd(1281)},T:function(a){var b=td[a];if(!b){switch(a){case 7939:b=Z.getSupportedExtensions()||[];b=b.concat(b.map(function(f){return"GL_"+f}));b=Fd(b.join(" "));break;case 7936:case 7937:case 37445:case 37446:(b=Z.getParameter(a))||wd(1280);b=b&&Fd(b);break;case 7938:b=Z.getParameter(7938);b=2<=x.version?"OpenGL ES 3.0 ("+b+")":"OpenGL ES 2.0 ("+b+")";b=Fd(b);break;case 35724:b=Z.getParameter(35724);var e=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);
null!==e&&(3==e[1].length&&(e[1]+="0"),b="OpenGL ES GLSL ES "+e[1]+" ("+b+")");b=Fd(b);break;default:wd(1280)}td[a]=b}return b},jb:function(a,b){if(2>x.version)return wd(1282),0;var e=ud[a];if(e)return 0>b||b>=e.length?(wd(1281),0):e[b];switch(a){case 7939:return e=Z.getSupportedExtensions()||[],e=e.concat(e.map(function(f){return"GL_"+f})),e=e.map(function(f){return Fd(f)}),e=ud[a]=e,0>b||b>=e.length?(wd(1281),0):e[b];default:return wd(1280),0}},Pa:function(a,b){b=Va(b);if(a=md[a]){var e=a,f=e.Ne,
h=e.xf,m;if(!f)for(e.Ne=f={},e.wf={},m=0;m<Z.getProgramParameter(e,35718);++m){var v=Z.getActiveUniform(e,m);var n=v.name;v=v.size;var q=Hd(n);q=0<q?n.slice(0,q):n;var u=e.kf;e.kf+=v;h[q]=[v,u];for(n=0;n<v;++n)f[u]=n,e.wf[u++]=q}e=a.Ne;f=0;h=b;m=Hd(b);0<m&&(f=parseInt(b.slice(m+1))>>>0,h=b.slice(0,m));if((h=a.xf[h])&&f<h[0]&&(f+=h[1],e[f]=e[f]||Z.getUniformLocation(a,b)))return f}else wd(1281);return-1},Ob:function(a,b,e){for(var f=Cd[b],h=0;h<b;h++)f[h]=R[e+4*h>>2];Z.invalidateFramebuffer(a,f)},
Pb:function(a,b,e,f,h,m,v){for(var n=Cd[b],q=0;q<b;q++)n[q]=R[e+4*q>>2];Z.invalidateSubFramebuffer(a,n,f,h,m,v)},Xb:function(a){return Z.isSync(sd[a])},Qa:function(a){return(a=ja[a])?Z.isTexture(a):0},Ra:function(a){Z.lineWidth(a)},Sa:function(a){a=md[a];Z.linkProgram(a);a.Ne=0;a.xf={}},qc:function(a,b,e,f,h,m){Z.uf.multiDrawArraysInstancedBaseInstanceWEBGL(a,R,b>>2,R,e>>2,R,f>>2,lb,h>>2,m)},rc:function(a,b,e,f,h,m,v,n){Z.uf.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(a,R,b>>2,e,R,f>>2,
R,h>>2,R,m>>2,lb,v>>2,n)},Ta:function(a,b){3317==a&&(vd=b);Z.pixelStorei(a,b)},tc:function(a){Z.readBuffer(a)},Ua:function(a,b,e,f,h,m,v){if(2<=x.version)if(Z.cf)Z.readPixels(a,b,e,f,h,m,v);else{var n=Id(m);Z.readPixels(a,b,e,f,h,m,n,v>>31-Math.clz32(n.BYTES_PER_ELEMENT))}else(v=Jd(m,h,e,f,v))?Z.readPixels(a,b,e,f,h,m,v):wd(1280)},dc:function(a,b,e,f){Z.renderbufferStorage(a,b,e,f)},$b:function(a,b,e,f,h){Z.renderbufferStorageMultisample(a,b,e,f,h)},Tb:function(a,b,e){Z.samplerParameterf(rd[a],b,
e)},Ub:function(a,b,e){Z.samplerParameteri(rd[a],b,e)},Vb:function(a,b,e){Z.samplerParameteri(rd[a],b,R[e>>2])},Va:function(a,b,e,f){Z.scissor(a,b,e,f)},Wa:function(a,b,e,f){for(var h="",m=0;m<b;++m){var v=f?R[f+4*m>>2]:-1;h+=Va(R[e+4*m>>2],0>v?void 0:v)}Z.shaderSource(pd[a],h)},Xa:function(a,b,e){Z.stencilFunc(a,b,e)},Ya:function(a,b,e,f){Z.stencilFuncSeparate(a,b,e,f)},Za:function(a){Z.stencilMask(a)},_a:function(a,b){Z.stencilMaskSeparate(a,b)},$a:function(a,b,e){Z.stencilOp(a,b,e)},ab:function(a,
b,e,f){Z.stencilOpSeparate(a,b,e,f)},bb:function(a,b,e,f,h,m,v,n,q){if(2<=x.version)if(Z.Ee)Z.texImage2D(a,b,e,f,h,m,v,n,q);else if(q){var u=Id(n);Z.texImage2D(a,b,e,f,h,m,v,n,u,q>>31-Math.clz32(u.BYTES_PER_ELEMENT))}else Z.texImage2D(a,b,e,f,h,m,v,n,null);else Z.texImage2D(a,b,e,f,h,m,v,n,q?Jd(n,v,f,h,q):null)},cb:function(a,b,e){Z.texParameterf(a,b,e)},db:function(a,b,e){Z.texParameterf(a,b,T[e>>2])},eb:function(a,b,e){Z.texParameteri(a,b,e)},fb:function(a,b,e){Z.texParameteri(a,b,R[e>>2])},nc:function(a,
b,e,f,h){Z.texStorage2D(a,b,e,f,h)},gb:function(a,b,e,f,h,m,v,n,q){if(2<=x.version)if(Z.Ee)Z.texSubImage2D(a,b,e,f,h,m,v,n,q);else if(q){var u=Id(n);Z.texSubImage2D(a,b,e,f,h,m,v,n,u,q>>31-Math.clz32(u.BYTES_PER_ELEMENT))}else Z.texSubImage2D(a,b,e,f,h,m,v,n,null);else u=null,q&&(u=Jd(n,v,h,m,q)),Z.texSubImage2D(a,b,e,f,h,m,v,n,u)},hb:function(a,b){Z.uniform1f(Kd(a),b)},ib:function(a,b,e){if(2<=x.version)b&&Z.uniform1fv(Kd(a),T,e>>2,b);else{if(288>=b)for(var f=Ld[b-1],h=0;h<b;++h)f[h]=T[e+4*h>>2];
else f=T.subarray(e>>2,e+4*b>>2);Z.uniform1fv(Kd(a),f)}},Yc:function(a,b){Z.uniform1i(Kd(a),b)},Zc:function(a,b,e){if(2<=x.version)b&&Z.uniform1iv(Kd(a),R,e>>2,b);else{if(288>=b)for(var f=Md[b-1],h=0;h<b;++h)f[h]=R[e+4*h>>2];else f=R.subarray(e>>2,e+4*b>>2);Z.uniform1iv(Kd(a),f)}},_c:function(a,b,e){Z.uniform2f(Kd(a),b,e)},$c:function(a,b,e){if(2<=x.version)b&&Z.uniform2fv(Kd(a),T,e>>2,2*b);else{if(144>=b)for(var f=Ld[2*b-1],h=0;h<2*b;h+=2)f[h]=T[e+4*h>>2],f[h+1]=T[e+(4*h+4)>>2];else f=T.subarray(e>>
2,e+8*b>>2);Z.uniform2fv(Kd(a),f)}},Xc:function(a,b,e){Z.uniform2i(Kd(a),b,e)},Wc:function(a,b,e){if(2<=x.version)b&&Z.uniform2iv(Kd(a),R,e>>2,2*b);else{if(144>=b)for(var f=Md[2*b-1],h=0;h<2*b;h+=2)f[h]=R[e+4*h>>2],f[h+1]=R[e+(4*h+4)>>2];else f=R.subarray(e>>2,e+8*b>>2);Z.uniform2iv(Kd(a),f)}},Vc:function(a,b,e,f){Z.uniform3f(Kd(a),b,e,f)},Uc:function(a,b,e){if(2<=x.version)b&&Z.uniform3fv(Kd(a),T,e>>2,3*b);else{if(96>=b)for(var f=Ld[3*b-1],h=0;h<3*b;h+=3)f[h]=T[e+4*h>>2],f[h+1]=T[e+(4*h+4)>>2],f[h+
2]=T[e+(4*h+8)>>2];else f=T.subarray(e>>2,e+12*b>>2);Z.uniform3fv(Kd(a),f)}},Tc:function(a,b,e,f){Z.uniform3i(Kd(a),b,e,f)},Sc:function(a,b,e){if(2<=x.version)b&&Z.uniform3iv(Kd(a),R,e>>2,3*b);else{if(96>=b)for(var f=Md[3*b-1],h=0;h<3*b;h+=3)f[h]=R[e+4*h>>2],f[h+1]=R[e+(4*h+4)>>2],f[h+2]=R[e+(4*h+8)>>2];else f=R.subarray(e>>2,e+12*b>>2);Z.uniform3iv(Kd(a),f)}},Rc:function(a,b,e,f,h){Z.uniform4f(Kd(a),b,e,f,h)},Qc:function(a,b,e){if(2<=x.version)b&&Z.uniform4fv(Kd(a),T,e>>2,4*b);else{if(72>=b){var f=
Ld[4*b-1],h=T;e>>=2;for(var m=0;m<4*b;m+=4){var v=e+m;f[m]=h[v];f[m+1]=h[v+1];f[m+2]=h[v+2];f[m+3]=h[v+3]}}else f=T.subarray(e>>2,e+16*b>>2);Z.uniform4fv(Kd(a),f)}},Ec:function(a,b,e,f,h){Z.uniform4i(Kd(a),b,e,f,h)},Fc:function(a,b,e){if(2<=x.version)b&&Z.uniform4iv(Kd(a),R,e>>2,4*b);else{if(72>=b)for(var f=Md[4*b-1],h=0;h<4*b;h+=4)f[h]=R[e+4*h>>2],f[h+1]=R[e+(4*h+4)>>2],f[h+2]=R[e+(4*h+8)>>2],f[h+3]=R[e+(4*h+12)>>2];else f=R.subarray(e>>2,e+16*b>>2);Z.uniform4iv(Kd(a),f)}},Gc:function(a,b,e,f){if(2<=
x.version)b&&Z.uniformMatrix2fv(Kd(a),!!e,T,f>>2,4*b);else{if(72>=b)for(var h=Ld[4*b-1],m=0;m<4*b;m+=4)h[m]=T[f+4*m>>2],h[m+1]=T[f+(4*m+4)>>2],h[m+2]=T[f+(4*m+8)>>2],h[m+3]=T[f+(4*m+12)>>2];else h=T.subarray(f>>2,f+16*b>>2);Z.uniformMatrix2fv(Kd(a),!!e,h)}},Hc:function(a,b,e,f){if(2<=x.version)b&&Z.uniformMatrix3fv(Kd(a),!!e,T,f>>2,9*b);else{if(32>=b)for(var h=Ld[9*b-1],m=0;m<9*b;m+=9)h[m]=T[f+4*m>>2],h[m+1]=T[f+(4*m+4)>>2],h[m+2]=T[f+(4*m+8)>>2],h[m+3]=T[f+(4*m+12)>>2],h[m+4]=T[f+(4*m+16)>>2],h[m+
5]=T[f+(4*m+20)>>2],h[m+6]=T[f+(4*m+24)>>2],h[m+7]=T[f+(4*m+28)>>2],h[m+8]=T[f+(4*m+32)>>2];else h=T.subarray(f>>2,f+36*b>>2);Z.uniformMatrix3fv(Kd(a),!!e,h)}},Ic:function(a,b,e,f){if(2<=x.version)b&&Z.uniformMatrix4fv(Kd(a),!!e,T,f>>2,16*b);else{if(18>=b){var h=Ld[16*b-1],m=T;f>>=2;for(var v=0;v<16*b;v+=16){var n=f+v;h[v]=m[n];h[v+1]=m[n+1];h[v+2]=m[n+2];h[v+3]=m[n+3];h[v+4]=m[n+4];h[v+5]=m[n+5];h[v+6]=m[n+6];h[v+7]=m[n+7];h[v+8]=m[n+8];h[v+9]=m[n+9];h[v+10]=m[n+10];h[v+11]=m[n+11];h[v+12]=m[n+12];
h[v+13]=m[n+13];h[v+14]=m[n+14];h[v+15]=m[n+15]}}else h=T.subarray(f>>2,f+64*b>>2);Z.uniformMatrix4fv(Kd(a),!!e,h)}},Jc:function(a){a=md[a];Z.useProgram(a);Z.Hf=a},Kc:function(a,b){Z.vertexAttrib1f(a,b)},Lc:function(a,b){Z.vertexAttrib2f(a,T[b>>2],T[b+4>>2])},Mc:function(a,b){Z.vertexAttrib3f(a,T[b>>2],T[b+4>>2],T[b+8>>2])},Nc:function(a,b){Z.vertexAttrib4f(a,T[b>>2],T[b+4>>2],T[b+8>>2],T[b+12>>2])},oc:function(a,b){Z.vertexAttribDivisor(a,b)},pc:function(a,b,e,f,h){Z.vertexAttribIPointer(a,b,e,f,
h)},Oc:function(a,b,e,f,h,m){Z.vertexAttribPointer(a,b,e,!!f,h,m)},Pc:function(a,b,e,f){Z.viewport(a,b,e,f)},lb:function(a,b,e,f){Z.waitSync(sd[a],b,(e>>>0)+4294967296*f)},tb:function(a){var b=K.length;a>>>=0;if(2147483648<a)return!1;for(var e=1;4>=e;e*=2){var f=b*(1+.2/e);f=Math.min(f,a+100663296);var h=Math;f=Math.max(a,f);h=h.min.call(h,2147483648,f+(65536-f%65536)%65536);a:{try{Qa.grow(h-jb.byteLength+65535>>>16);nb();var m=1;break a}catch(v){}m=void 0}if(m)return!0}return!1},nb:function(){return x?
x.Uf:0},wb:function(a,b){var e=0;Od().forEach(function(f,h){var m=b+e;h=lb[a+4*h>>2]=m;for(m=0;m<f.length;++m)kb[h++>>0]=f.charCodeAt(m);kb[h>>0]=0;e+=f.length+1});return 0},xb:function(a,b){var e=Od();lb[a>>2]=e.length;var f=0;e.forEach(function(h){f+=h.length+1});lb[b>>2]=f;return 0},Jb:function(a){if(!noExitRuntime){if(w.onExit)w.onExit(a);Ra=!0}wa(a,new Ha(a))},P:function(){return 52},ob:function(){return 52},Cb:function(){return 52},pb:function(){return 70},V:function(a,b,e,f){for(var h=0,m=
0;m<e;m++){var v=lb[b>>2],n=lb[b+4>>2];b+=8;for(var q=0;q<n;q++){var u=K[v+q],D=Qd[a];0===u||10===u?((1===a?Ka:Ja)(Ta(D,0)),D.length=0):D.push(u)}h+=n}lb[f>>2]=h;return 0},c:function(){return La},l:ae,o:be,m:ce,K:de,S:ee,$:fe,R:ge,q:he,z:ie,u:je,w:ke,Mb:le,Kb:me,Lb:ne,d:function(a){La=a},rb:function(a,b,e,f){return Ud(a,b,e,f)}};
(function(){function a(h){w.asm=h.exports;Qa=w.asm.cd;nb();ob=w.asm.fd;qb.unshift(w.asm.dd);tb--;w.monitorRunDependencies&&w.monitorRunDependencies(tb);0==tb&&(null!==ub&&(clearInterval(ub),ub=null),vb&&(h=vb,vb=null,h()))}function b(h){a(h.instance)}function e(h){return Bb().then(function(m){return WebAssembly.instantiate(m,f)}).then(function(m){return m}).then(h,function(m){Ja("failed to asynchronously prepare wasm: "+m);Na(m)})}var f={a:oe};tb++;w.monitorRunDependencies&&w.monitorRunDependencies(tb);
if(w.instantiateWasm)try{return w.instantiateWasm(f,a)}catch(h){return Ja("Module.instantiateWasm callback failed with error: "+h),!1}(function(){return Ma||"function"!=typeof WebAssembly.instantiateStreaming||wb()||yb.startsWith("file://")||Aa||"function"!=typeof fetch?e(b):fetch(yb,{credentials:"same-origin"}).then(function(h){return WebAssembly.instantiateStreaming(h,f).then(b,function(m){Ja("wasm streaming compile failed: "+m);Ja("falling back to ArrayBuffer instantiation");return e(b)})})})().catch(ea);
return{}})();w.___wasm_call_ctors=function(){return(w.___wasm_call_ctors=w.asm.dd).apply(null,arguments)};var Gd=w._malloc=function(){return(Gd=w._malloc=w.asm.ed).apply(null,arguments)},Lc=w._free=function(){return(Lc=w._free=w.asm.gd).apply(null,arguments)},Kc=w.___getTypeName=function(){return(Kc=w.___getTypeName=w.asm.hd).apply(null,arguments)};w.___embind_register_native_and_builtin_types=function(){return(w.___embind_register_native_and_builtin_types=w.asm.id).apply(null,arguments)};
var pe=w._setThrew=function(){return(pe=w._setThrew=w.asm.jd).apply(null,arguments)},qe=w.stackSave=function(){return(qe=w.stackSave=w.asm.kd).apply(null,arguments)},re=w.stackRestore=function(){return(re=w.stackRestore=w.asm.ld).apply(null,arguments)};w.dynCall_viji=function(){return(w.dynCall_viji=w.asm.md).apply(null,arguments)};w.dynCall_vijiii=function(){return(w.dynCall_vijiii=w.asm.nd).apply(null,arguments)};w.dynCall_viiiiij=function(){return(w.dynCall_viiiiij=w.asm.od).apply(null,arguments)};
w.dynCall_jiiiijiiiii=function(){return(w.dynCall_jiiiijiiiii=w.asm.pd).apply(null,arguments)};w.dynCall_viiij=function(){return(w.dynCall_viiij=w.asm.qd).apply(null,arguments)};w.dynCall_jii=function(){return(w.dynCall_jii=w.asm.rd).apply(null,arguments)};w.dynCall_vij=function(){return(w.dynCall_vij=w.asm.sd).apply(null,arguments)};w.dynCall_iiij=function(){return(w.dynCall_iiij=w.asm.td).apply(null,arguments)};w.dynCall_iiiij=function(){return(w.dynCall_iiiij=w.asm.ud).apply(null,arguments)};
w.dynCall_viij=function(){return(w.dynCall_viij=w.asm.vd).apply(null,arguments)};w.dynCall_ji=function(){return(w.dynCall_ji=w.asm.wd).apply(null,arguments)};w.dynCall_iij=function(){return(w.dynCall_iij=w.asm.xd).apply(null,arguments)};w.dynCall_jiiiii=function(){return(w.dynCall_jiiiii=w.asm.yd).apply(null,arguments)};w.dynCall_jiiiiii=function(){return(w.dynCall_jiiiiii=w.asm.zd).apply(null,arguments)};w.dynCall_jiiiiji=function(){return(w.dynCall_jiiiiji=w.asm.Ad).apply(null,arguments)};
w.dynCall_iijj=function(){return(w.dynCall_iijj=w.asm.Bd).apply(null,arguments)};w.dynCall_iiiji=function(){return(w.dynCall_iiiji=w.asm.Cd).apply(null,arguments)};w.dynCall_iiji=function(){return(w.dynCall_iiji=w.asm.Dd).apply(null,arguments)};w.dynCall_iijjiii=function(){return(w.dynCall_iijjiii=w.asm.Ed).apply(null,arguments)};w.dynCall_vijjjii=function(){return(w.dynCall_vijjjii=w.asm.Fd).apply(null,arguments)};w.dynCall_jiji=function(){return(w.dynCall_jiji=w.asm.Gd).apply(null,arguments)};
w.dynCall_viijii=function(){return(w.dynCall_viijii=w.asm.Hd).apply(null,arguments)};w.dynCall_iiiiij=function(){return(w.dynCall_iiiiij=w.asm.Id).apply(null,arguments)};w.dynCall_iiiiijj=function(){return(w.dynCall_iiiiijj=w.asm.Jd).apply(null,arguments)};w.dynCall_iiiiiijj=function(){return(w.dynCall_iiiiiijj=w.asm.Kd).apply(null,arguments)};function ae(a,b){var e=qe();try{return Db(a)(b)}catch(f){re(e);if(f!==f+0)throw f;pe(1,0)}}
function be(a,b,e){var f=qe();try{return Db(a)(b,e)}catch(h){re(f);if(h!==h+0)throw h;pe(1,0)}}function ce(a,b,e,f){var h=qe();try{return Db(a)(b,e,f)}catch(m){re(h);if(m!==m+0)throw m;pe(1,0)}}function he(a,b){var e=qe();try{Db(a)(b)}catch(f){re(e);if(f!==f+0)throw f;pe(1,0)}}function je(a,b,e,f){var h=qe();try{Db(a)(b,e,f)}catch(m){re(h);if(m!==m+0)throw m;pe(1,0)}}function de(a,b,e,f,h){var m=qe();try{return Db(a)(b,e,f,h)}catch(v){re(m);if(v!==v+0)throw v;pe(1,0)}}
function ee(a,b,e,f,h,m,v){var n=qe();try{return Db(a)(b,e,f,h,m,v)}catch(q){re(n);if(q!==q+0)throw q;pe(1,0)}}function ke(a,b,e,f,h){var m=qe();try{Db(a)(b,e,f,h)}catch(v){re(m);if(v!==v+0)throw v;pe(1,0)}}function ge(a){var b=qe();try{Db(a)()}catch(e){re(b);if(e!==e+0)throw e;pe(1,0)}}function ie(a,b,e){var f=qe();try{Db(a)(b,e)}catch(h){re(f);if(h!==h+0)throw h;pe(1,0)}}function le(a,b,e,f,h,m){var v=qe();try{Db(a)(b,e,f,h,m)}catch(n){re(v);if(n!==n+0)throw n;pe(1,0)}}
function ne(a,b,e,f,h,m,v,n,q,u){var D=qe();try{Db(a)(b,e,f,h,m,v,n,q,u)}catch(G){re(D);if(G!==G+0)throw G;pe(1,0)}}function me(a,b,e,f,h,m,v){var n=qe();try{Db(a)(b,e,f,h,m,v)}catch(q){re(n);if(q!==q+0)throw q;pe(1,0)}}function fe(a,b,e,f,h,m,v,n,q,u){var D=qe();try{return Db(a)(b,e,f,h,m,v,n,q,u)}catch(G){re(D);if(G!==G+0)throw G;pe(1,0)}}var se;function Ha(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}vb=function te(){se||ue();se||(vb=te)};
function ue(){function a(){if(!se&&(se=!0,w.calledRun=!0,!Ra)){Cb(qb);aa(w);if(w.onRuntimeInitialized)w.onRuntimeInitialized();if(w.postRun)for("function"==typeof w.postRun&&(w.postRun=[w.postRun]);w.postRun.length;){var b=w.postRun.shift();rb.unshift(b)}Cb(rb)}}if(!(0<tb)){if(w.preRun)for("function"==typeof w.preRun&&(w.preRun=[w.preRun]);w.preRun.length;)sb();Cb(pb);0<tb||(w.setStatus?(w.setStatus("Running..."),setTimeout(function(){setTimeout(function(){w.setStatus("")},1);a()},1)):a())}}
w.run=ue;if(w.preInit)for("function"==typeof w.preInit&&(w.preInit=[w.preInit]);0<w.preInit.length;)w.preInit.pop()();ue();


  return CanvasKitInit.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = CanvasKitInit;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return CanvasKitInit; });
else if (typeof exports === 'object')
  exports["CanvasKitInit"] = CanvasKitInit;
